@import "../../components/assets/stylesheets/global";

#notification-container {
  min-height: 50%;

  .boxy {
    height: 100%;
    padding: 40px;
    display: flex;
    flex-direction: column;

    .notifications-header {
      margin-bottom: 40px;

      .header-title {
        height: 64px;
        line-height: 64px;
        font-size: 32px;
        font-weight: bold;
      }

      .header-description {
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .notification-list {
      flex: 1;
      overflow: auto;
    }

    .notification-tabs {
      height: 48px;
      margin: 0 -40px 24px;
      border-bottom: solid 1px $bluey-white;

      .tabs {
        ul {
          li {
            button {
              height: 48px;
              width: 144px;
            }
          }
        }
      }
    }
  }
}
