@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.placeholder-table {
  @include directional-margin(initial, 48px);
  width: 100%;
  margin-top: 16px;

  tr {
    td {
      text-align: center;

      .placeholder {
        height: 20px;
        background: linear-gradient(-90deg, $grey, $light-grey);
        background-size: 400% 400%;
        animation: Gradient 1.5s ease infinite;

        &.select-placeholder {
          width: 20px;
          margin: 14px auto;
        }

        &.data-placeholder {
          min-width: 50%;
          margin: 14px 30px;
        }

        &.round {
          border-radius: 8px;
        }

        @keyframes Gradient {
          0% {
            background-position: 0% 50%;
          }

          50% {
            background-position: 100% 50%;
          }

          100% {
            background-position: 0% 50%;
          }
        }
      }
    }
  }
}
