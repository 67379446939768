@import "../../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

#MembersManagementContainer {
  padding: 0;
  width: 542px;

  .program-members-modal-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
  }

  .manage-members-header {
    text-align: center;
    padding: 24px 24px 0;
  }

  > .footer-actions {
    background-color: unset;

    .button-component {
      &:last-child {
        @include directional-margin(initial, auto);
        margin-left: auto;
      }
    }
  }
}
