@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.tabs {
  ul {
    border-bottom: 0 !important;

    li {
      &.is-active {
        a,
        button {
          color: $blue;
          border-bottom-color: $blue !important;
        }
      }

      a,
      button {
        cursor: pointer;
        outline: none;
        border-bottom-color: transparent !important;
        font-size: 13px;
        font-weight: bold;
        align-items: center;
        border: 0;
        background: none;
        border-bottom-style: solid;
        display: flex;
        justify-content: center;
        margin-bottom: -1px;
        vertical-align: top;
        line-height: 3em;
        color: $grey;

        i {
          &.mdi {
            @include directional-margin(0, 8px);
            font-size: 24px;
          }
        }
      }
    }
  }
}
