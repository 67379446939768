@import "../../components/assets/stylesheets/global";

.prompt-message {
  z-index: 10000;
  border-radius: 4px;

  .modal-content {
    box-shadow: 0 0 3px 0 $blue;
    border: solid 1px $blue;
    max-width: 640px;
  }

  .boxy {
    padding: 48px;

    > h3 + p {
      margin-bottom: 48px;
    }

    .columns {
      flex-direction: column;
    }
  }
}
