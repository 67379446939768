@import "../../../components/assets/stylesheets/global";

.program-card {
  min-height: 130px;
  box-shadow: 0 1px 2px transparentize($grey, 0.7);
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  margin: 8px;
  color: $dark-blue;
  background-color: $white;
  max-width: 100%;

  .program-card-header {
    position: relative;
    height: 144px;
    box-shadow: none;
    overflow: hidden;
    background-color: $white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .program-card-body {
    padding: 16px;
    border-top: 1px solid transparentize($dark-blue, 0.95);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-transform: uppercase;

    .program-card-title {
      height: 32px;
      line-height: 32px;
      font-size: 16px;
      font-weight: bold;
      width: 100%;
    }

    .submissions {
      font-weight: lighter;
      font-size: 12px;
    }
  }

  &:focus,
  &:hover {
    outline: none;
    transform: translateY(-4px);
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    box-shadow: 0 2px 10px transparentize($grey, 0.85);

    .program-card-actions {
      pointer-events: all;
      opacity: 1;
      transition: opacity 0.1s ease;
    }
  }

  .program-card-actions {
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.2s ease;
    top: 8px;
    right: 8px;
    position: absolute;

    button {
      padding: 0;
      margin: 0;
    }

    i {
      &.mdi {
        background: $white;
        border-radius: 100px;
        color: $grey;
        width: 32px;
        height: 32px;
        box-shadow: 0 6px 8px transparentize($grey, 0.95),
          0 1px 2px transparentize($grey, 0.7);
        padding: 0;
        font-size: 24px;
        line-height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: middle;
      }
    }
  }
}

.program-action {
  align-items: center;
  justify-content: normal !important;

  i {
    color: $grey;
    font-size: 25px;
  }
}
