@import "../../components/assets/stylesheets/global";

$start: left;
$end: right;

$default-dir: ltr;
$opposite-dir: rtl;

$flip-chevron-value: 0deg;
$page-bg-color: #f4f7fb;
$box-bg-color: #fff;
$logo-bg-color: #268aff;
$list-item-bg-color: #f7f7f7;
$input-bg-color: #f4f4f4;

$page-text-color: #363636;
$primary-color: #0076ff;
$secondary-color: #4a90e2;
$primary-text-color: #fff;
$input-text-color: #565656;
$input-placeholder-color: #b5b5b5;

$input-border-color: #e0e0e0;
$box-border-color: rgba(0, 61, 76, 0.07);
$list-item-border-color: #f0f0f0;
$button-shadow-color: rgba(0, 0, 0, 0.12);
$nav-shadow-color: rgba(0, 0, 0, 0.039);

$border-radius: 4px;

$body-bg: url("./images/intro.png");

@if $THEME == moc {
  $body-bg: "";
}

// We're using 8pt grid.
// if you modify this grid bit,
// it will affect all explicit:
// margins, padding, widths and heights

$gridbit: 8px;

@function gridbits($multiplier) {
  @return $multiplier * $gridbit;
}

.body {
  background-color: $page-bg-color;
  color: $page-text-color;
  font-family: "Dubai", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: gridbits(2);
  width: 100vw;
  height: 100vh;
  margin: 0;
  text-align: center;
  direction: $default-dir;
  display: grid;
  grid-template-columns: gridbits(12) repeat(2, 4fr) 1fr;
  grid-template-rows: gridbits(6) 8fr 1fr;
  grid-column-gap: gridbits(3);
  grid-row-gap: gridbits(2);
  align-items: center;
  justify-items: center;
  background-image: $body-bg;
  background-repeat: no-repeat;
  background-position: bottom;

  nav {
    grid-area: 1 / 1 / 2 / 5;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    box-shadow: 1px gridbits(0.5) 1px $nav-shadow-color;
    background-color: $box-bg-color;

    .lang-switcher {
      text-decoration: none;
      color: $input-text-color;
      align-self: center;
      width: 100px;
      line-height: gridbits(12);
      transition: background ease 0.2s;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $secondary-color;
      }
    }

    .razeen {
      display: inline-block;
      background: url("./images/razeen.svg") no-repeat;
      width: gridbits(16.5);
      background-size: auto gridbits(1.5);
      background-position: center;
      background-color: $blue;
      height: 100%;
    }
  }

  .txt {
    text-align: $start;
    grid-area: 2 / 2 / 3 / 3;

    h2 {
      font-size: gridbits(4);
      text-transform: capitalize;
      margin: 0;
    }
  }

  .footer-sign {
    grid-area: 3 / 1 / 4 / 5;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: gridbits(4);
      height: gridbits(4);
    }
  }
}

.box {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto;
  background: $box-bg-color;
  border: 1px solid $box-border-color;
  box-shadow: 0 1px gridbits(0.5) $button-shadow-color;
  border-radius: gridbits(2);
  width: 80%;
  max-width: 540px;
  padding: 32px;
  grid-area: 2 / 3 / 3 / 4;

  h2 {
    margin-top: 0;
    margin-bottom: gridbits(4);
    font-size: gridbits(4);
    font-weight: 600;
  }

  .field,
  .actions {
    margin-bottom: gridbits(2);
    position: relative;
    display: flex;
    align-content: center;
    justify-content: flex-end;

    .password-toggle {
      height: 100%;
      padding-#{$end}: gridbits(1.5);
      position: absolute;
      display: flex;
      cursor: pointer;
    }
  }

  label {
    width: 100%;
    box-sizing: border-box;
    font-size: gridbits(2);
    margin-bottom: gridbits(1) / 2;
  }

  input {
    &[type="text"],
    &[type="email"],
    &[type="password"],
    &[type="phone"] {
      transition: all 0.3s ease-in-out;
      width: 100%;
      box-sizing: border-box;
      font-size: gridbits(2);
      height: gridbits(6);
      color: $input-text-color;
      background-color: $input-bg-color;
      border: solid 1px transparent;
      border-radius: gridbits(0.5);
      padding: gridbits(2);

      &::placeholder {
        //  Chrome/Opera/Safari
        color: $input-placeholder-color;
      }

      &:focus {
        outline: none;
        border: 1px solid $button-shadow-color;
        background: $box-bg-color;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        border: 1px solid $button-shadow-color;
      }
    }
  }

  [type="submit"],
  [type="button"],
  button,
  .btn {
    transition: all 0.3s ease;
    width: 100%;
    margin-top: gridbits(2);
    border: 0;
    border-radius: $border-radius;
    background-color: $blue;
    color: $primary-text-color;
    padding: gridbits(1) gridbits(2);
    font-size: gridbits(2);
    font-weight: bold;
    height: gridbits(6);
    box-shadow: 0 gridbits(0.5) gridbits(1.5) 0 $button-shadow-color;
    text-align: center;
    text-shadow: 0 1px 1px $button-shadow-color;
    cursor: pointer;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 gridbits(0.5) gridbits(1.5) 0 $button-shadow-color;

      background-color: lighten($blue, 10%);
    }
  }

  a {
    transition: all 0.3s ease;

    color: $secondary-color;

    &:hover {
      color: lighten($primary-color, 10%);
    }
  }

  .title {
    text-align: center;
    margin: 0 auto gridbits(4);
  }

  .list-item {
    opacity: 0.8;
    cursor: pointer;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    padding: gridbits(2) gridbits(4);
    margin: auto gridbits(-4);
    border-top: 1px $list-item-border-color solid;
    text-align: $start;
    transition: background ease 0.2s, opacity ease 0.2s;

    &:hover {
      opacity: 1;
      background: $list-item-bg-color;
    }

    .chevron {
      position: absolute;
      #{$end}: gridbits(4);
      top: 28px;
      opacity: 0.2;
      transform: rotateY($flip-chevron-value);
    }

    .link {
      color: $primary-color;
      font-size: gridbits(2);
      direction: ltr;
    }
  }
}

@media only screen and (max-device-width: 768px) {
  .body {
    grid-template-columns: 1fr;
    grid-template-rows: gridbits(8) repeat(2, 4fr) 1fr;
    gap: 0;

    nav {
      box-shadow: none;
      background-color: transparent;
      flex-direction: column;

      .razeen {
        width: 100%;
      }

      .lang-switcher {
        align-self: flex-end;
      }
    }

    .txt {
      text-align: center;
      grid-area: 2 / 1 / 3 / 2;
    }

    .box {
      grid-area: 3 / 1 / 4 / 2;
    }

    .footer {
      grid-area: 4 / 1 / 5 / 2;
    }
  }
}

@media only screen and (max-device-width: 480px) {
  .txt {
    display: none;
  }
}
