@import "../../components/assets/stylesheets/global";

.button {
  &.button-component {
    color: $white;
    padding: 0 16px;
    min-width: 144px;
    height: 48px;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    background: $blue;
    // background: linear-gradient(90deg, rgba(94,69,255,1) 0%, rgb(0, 40, 179) 100%);
    // background-color: $dodge-blue;
    box-shadow: 0 10px 19px -12px $dodge-blue;
    transition: all 150ms ease-in-out;
    text-shadow: 0 2px 4px transparentize($black, 0.9);

    &.is-white {
      color: $blue;
      border: 1px solid $blue;
      box-shadow: 0 0 transparent !important;
      // background: #FDEEEB !important;
      background: transparent !important;
      text-shadow: none;

      &:hover,
      &:focus {
        background-color: transparentize($blue, 0.95);
        color: $blue;
      }

      &.is-loading {
        &::after {
          border-color: transparent transparent $blue $blue !important;
        }
      }
    }
    &.is-white-flat {
      color: $blue;
      box-shadow: 0 0 transparent !important;
      background: transparent !important;
      text-shadow: none;

      &:hover,
      &:focus {
        background-color: transparentize($blue, 0.95);
        color: $blue;
      }

      &.is-loading {
        &::after {
          border-color: transparent transparent $blue $blue !important;
        }
      }
    }
    &:hover {
      box-shadow: none;
      background-color: $blue;
    }

    i {
      &.mdi {
        @include directional(padding, 0 8px 0 0, 0 0 0 8px);
      }
    }

    &.secondary {
      color: $blue;
      box-shadow: inset 0 0 0 2px $light-cyan !important;
      background: transparent !important;
      text-shadow: none;

      &:hover,
      &:focus {
        box-shadow: inset 0 0 0 2px transparentize($blue, 0.15) !important;
      }
    }

    &.chameleon {
      box-shadow: none !important;
      background: $white;
      color: inherit;
      text-shadow: none;
    }

    &.small {
      font-size: 13px;
      min-width: auto;
    }

    &.tiny {
      min-width: auto;
      font-size: 13px;
    }
  }
}
