@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.workspace {
  max-width: 1104px;
  margin: auto;

  .bread-crumb {
    display: flex;
    text-transform: uppercase;
    font-size: 14px;
    padding: 8px;
    font-weight: 500;
    color: $dark-blue;

    li {
      &.clickable {
        color: transparentize($dark-blue, 0.25);
        cursor: pointer;

        &:hover {
          color: $dark-blue;
        }

        &::after {
          @include directional(content, "\F142", "\F141");
          @include directional-rtl(unicode-bidi, embed);
          margin: 5px;
          font: normal 500 normal 14px/1 "Material Design Icons";
        }
      }
    }
  }

  .empty-state {
    text-align: center;
    font-size: 32px;
    font-weight: 200;
    color: transparentize($grey, 0.65);

    span {
      margin-left: 16px;
    }
  }

  .workspace-name {
    @include directional-padding(0, 24px);
    font-size: 14px;
    color: $dark-blue;
    text-transform: uppercase;
    line-height: 40px;
  }

  .workspace-programs {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }

  #Workspaces {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 350px));
    grid-template-rows: repeat(auto-fill, minmax(120px, 170px));
    grid-gap: 25px;

    .w-container {
      border-radius: 4px;
      box-shadow: 0 1px 3px 1px transparentize($black, 0.96);
      display: grid;
      cursor: pointer;

      &:hover {
        transform: translateY(-4px);
        transition: transform 0.1s ease, box-shadow 0.1s ease;
        box-shadow: 0 2px 10px transparentize($grey, 0.85);
      }

      .w-background {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        overflow: hidden;
        border-radius: 4px;
        width: 100%;
        height: 170px;
        min-height: 120px;
        max-height: 170px;
        background: $white;
        grid-column: 1 / 1;
        grid-row: 1 / 1;

        .program {
          box-shadow: 0 1px 3px 1px transparentize($black, 0.96);

          &.many {
            width: 50%;
            height: 50%;
          }

          &.one {
            width: 100%;
            height: 100%;
          }
        }
      }

      .w-name {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: transparentize($black, 0.6);
        border-radius: 4px;
        color: $white;

        h2 {
          text-transform: capitalize;
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
  }
}
