@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.submissions-table {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 100%;
  height: calc(100vh - 182px);

  .table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease;
    border-bottom: 1px solid transparentize($dark-blue, 0.75);
    height: 56px;
    text-align: left;
    white-space: nowrap;
    width: fit-content;
    min-width: 100%;

    .table-cell {
      text-transform: capitalize;
      width: 160px;
      min-width: 160px;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;

      &.sortable {
        @include directional-txt-align;
        @include directional-padding(initial, 40px);
        vertical-align: middle;
        cursor: pointer;
      }

      &.id-cell {
        @include directional-padding(initial, 0);
      }

      &.checkbox-cell {
        @include directional-rtl(padding-right, 0);
        @include directional-rtl(padding-left, 0);
        @include directional-rtl(text-align, center);
        text-align: center;
      }
    }

    .minus-btn {
      display: inline-block;
      height: 14px;
      width: 14px;
      text-align: center;
      vertical-align: middle;
      padding: 0;
      line-height: 0.2;
    }

    .select-all-container {
      @include directional-padding(32px, initial);
      display: flex;
      justify-content: center;
      align-items: center;

      .select-all-menu {
        margin: 0;
      }

      .dropdown-content {
        border: 1px solid $blue;
      }

      .dropdown-item {
        &.is-text {
          display: flex;
          justify-content: space-between;
        }
      }

      .all-count {
        color: transparentize($dark-blue, 0.25);
      }
    }
  }

  .table-data {
    font-weight: 400;
    color: $dark-blue;
    flex: 1;
    overflow: auto;

    .submission {
      @include directional-border(0, solid 2px transparent);
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      cursor: pointer;
      margin: 0;
      transition: all 250ms ease-out;
      width: fit-content;
      min-width: 100%;

      .table-cell {
        @include directional-txt-align;
        @include directional-padding(initial, 40px);
        vertical-align: middle;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 160px;
        min-width: 160px;
        max-width: 160px;
        overflow: hidden;

        .label-tag {
          background: $bluey-white;
          padding: 0 9px;
          border-radius: 55px;
          font-size: 14px;
          color: $grey;
        }

        &.id-cell {
          @include directional-padding(initial, 0);
        }

        &.checkbox-cell {
          @include directional(text-align, center, center);
          @include directional-rtl(padding-right, 0);
          @include directional-rtl(padding-left, 0);
          padding: 0;
        }
      }

      &.active-submission {
        @include directional-border(0, solid 2px $blue);
        color: $blue;
        background-color: transparentize($blue, 0.95);

        .label-tag {
          background: $dodge-blue;
          color: $white;
        }
      }

      &:hover {
        background: transparentize($blue, 0.95);
      }
    }
  }

  .select-all-container {
    @include directional-padding(32px, initial);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.table-data {
  font-weight: 400;
  color: $dark-blue;
  flex: 1;
  overflow: auto;

  .submission {
    @include directional-border(0, solid 2px transparent);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    cursor: pointer;
    margin: 0;

    .paginator {
      border-top: solid 1px $bluey-white;
    }

    .table-cell {
      display: inline-block;
      width: 293px;
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 240px;
    }

    .paging {
      text-align: center;
    }
  }

  // collapse action
  .submissions-table {
    min-width: 100%;
  }

  .is-collapsed + .program-content-pane {
    .submissions-table {
      &.collapsed {
        min-width: 376px;
      }
    }
  }

  .program-content-pane {
    .submissions-table {
      &.collapsed {
        min-width: 0;
        .table-cell {
          min-width: 80px;
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .is-collapsed + .program-content-pane {
      .submissions-table {
        &.collapsed {
          min-width: 0;
        }
      }
    }
  }

  .select-all-menu {
    .all-count {
      color: transparentize($dark-blue, 0.25);
    }
  }
}
