@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.user-info {
  @include directional-margin(initial, auto);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: 260px;
  color: transparentize($dark-blue, 0.25);

  .username {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    user-select: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
    padding: 0 16px;

    .user-avatar {
      height: 32px;
      width: 32px;
      font-size: 18px;
      flex: 0 0 32px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    h5 {
      font-size: 14px;
      font-weight: 500;
    }

    &:hover {
      background-color: transparentize($blue, 0.95);
    }
  }

  .notifications {
    justify-self: flex-end;
  }
}
