@import "../../../components/assets/stylesheets/_global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

#MembersManagementContainer {
  .create-group-container {
    padding: 24px 24px 32px;
    cursor: pointer;

    .boxy {
      padding: 0;
    }

    .create-new-group {
      padding: 16px;

      .icon-button {
        display: flex;
        align-items: center;
        color: $blue;
        height: 16px;
        margin: 0;

        .icon-button-text {
          @include directional-padding(initial, 8px);
          font-weight: bold;
          text-transform: uppercase;
          line-height: 16px;
        }
      }
    }

    .create-group-form {
      .form-inputs {
        height: 186px;
        padding: 24px 24px 32px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        .Select-control {
          display: flex;
          align-items: center;
        }
      }

      .footer-actions {
        padding: 0;
        height: 48px;
      }
    }
  }

  .button {
    &.button-component {
      &.is-white {
        min-width: 50%;
      }
    }
  }
}
