@import "../../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.funnels_settings {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  background-color: transparentize($black, 0.14);
  box-shadow: 0 1px 3px 1px transparentize($black, 0.96);
  overflow: auto;
  display: flex;
  flex-direction: column;
  max-height: 100%;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }

  .form-buttons {
    display: flex;
    justify-content: flex-start;
    border-top: transparentize($black, 0.96) solid 1px;
    padding-top: 16px;

    .copy-button {
      @include directional(margin-left, auto, 8px);
      @include directional-rtl(margin-right, auto);
    }

    .delete-button,
    .copy-button {
      color: $dodge-blue;
    }

    #save-button {
      margin-bottom: 16px;
    }
  }

  .funnels_settings_form_container {
    margin: 32px 8%;
    padding: 40px;
    border-radius: 4px;
    background: $bluey-white;
    padding-bottom: 0;

    .new_input_source_box {
      padding: 0;
    }

    .boxy {
      &.fields {
        padding: 0;

        .check-all-checkbox {
          border: 0;
          border-bottom: 1px solid transparentize($light-grey, 0.4);
          border-top: 1px solid transparentize($light-grey, 0.4);
          width: 100%;
          padding: 8px;
          padding-left: 8px;
          margin: 0;
          background: $muted-white;
        }
      }
    }
  }

  .control {
    margin-bottom: 20px;

    &.column {
      max-width: 50%;
    }
  }

  .label-with-line {
    border-bottom: 1px $light-grey solid;
    overflow: visible;
    height: 9px;
    margin: 5px 0 20px;

    & > span {
      @include directional-padding(20px, 0);
      background-color: $bluey-white;
      color: $dark-blue;
      position: relative;
      bottom: 5px;
    }
  }

  .input_sources_container {
    margin-top: 62px;
  }
}

.boxy {
  background-color: $white;
  box-shadow: 0 6px 8px transparentize($grey, 0.95),
    0 1px 2px transparentize($grey, 0.7);
  border-radius: 0;
  padding: 8px;
}
