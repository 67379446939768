@import "../../components/assets/stylesheets/global";

.divider {
  min-height: 1px;
  width: 100%;
  padding: 0 16px;
  color: $dark-blue;
}

.divider-dark {
  border-color: transparentize($dark-blue, 0.95);
}

.divider-light {
  border-color: transparentize($white, 0.98);
}

.divider-top {
  border-top-width: 1px;
  border-top-style: solid;
  margin-top: 8px;
}

.divider-bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  margin-bottom: 8px;
}
