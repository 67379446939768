@import "../../components/assets/stylesheets/global";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border-bottom: 1px solid transparentize($dark-blue, 0.95);
  line-height: normal;
  height: 80px;
}

.header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  widows: 100%;

  h6 {
    @include directional-margin(initial, 16px);
    margin-left: 16px;
  }
}

.header-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

:only-child {
  .header-center &,
  .header-left &,
  .header-right & {
    width: 100%;
  }
}
