@import "../../components/assets/stylesheets/global";

.circle-button {
  width: 48px !important;
  height: 48px !important;
  padding: 0 12px !important;
  box-shadow: 0 1px 2px 0 transparentize($black, 0.85) !important;
  border-radius: 50%;
}

.circle-button-icon {
  color: $white !important;
  text-shadow: 0 1px 1px transparentize($black, 0.9) !important;
  font-size: 24px !important;
  line-height: 36px;
  height: 36px;
}
