@import "../../components/assets/stylesheets/global";

.input-component {
  input {
    &.input {
      box-shadow: 0 1px 1px 0 transparentize($black, 0.92);
      border: solid 1px $light-grey;

      border-radius: 4px;
      height: 48px;
      transition: all 200ms ease-in-out;

      &:hover {
        box-shadow: 0 2px 5px 0 transparentize($black, 0.99);
        transition: all 200ms ease-in-out;
      }

      &:focus {
        border-color: $blue;
        box-shadow: 0 2px 5px transparentize($dodge-blue, 0.75);
      }

      + div {
        &.icon {
          &.is-right {
            height: 100%;
            z-index: auto;
          }
        }
      }
    }
  }
}

.multiline {
  margin-bottom: 8px;

  textarea {
    min-height: 88px;
  }
}
