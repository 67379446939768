@import "../../components/assets/stylesheets/global";

div {
  .members-button {
    display: flex;
    width: 72px;
    border: 0;
    background: transparent;
    padding: 0;
    margin: 0 24px;
    outline: none;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    line-height: 100%;

    i {
      font-size: 18px;
      color: $light-grey;
    }
  }
}
