@import "../../components/assets/stylesheets/global";

.edit-field-btn {
  background-color: $blue;
  border: none;
  border-radius: 5px;
  color: $white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: darken($blue, 10%);
  }
}
