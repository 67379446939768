#SubmissionActivities {
  overflow-y: scroll;
  height: calc(100vh - 144px - 76px - 81px - 80px - 70px);
}

.comment-input {
  textarea {
    resize: none;
  }
}
