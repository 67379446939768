@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

#create-field-container {
  .add-new-field {
    border: solid 1px $bluey-white;
    border-left: solid 2px $blue;
    border-bottom: 0;

    .boxy {
      padding: 0;
      display: flex;

      .field-edit {
        width: 512px;
        padding-left: 60px;

        .input-component {
          padding: 24px 24px 0;
        }

        .input {
          border: solid 1px transparent !important;
          box-shadow: none !important;
          background: transparentize($black, 0.95);
          padding: 16px;

          &:hover {
            border: 1px solid transparentize($black, 0.9) !important;
          }

          &:focus {
            border: 1px solid transparentize($black, 0.9) !important;
            outline: none !important;
            box-shadow: none;
            background: $white;
            transition: all 250ms ease-in-out !important;
          }
        }

        .icon-menu-component {
          padding: 24px;
        }

        .divider {
          margin: 0;
        }

        .required-checkbox {
          padding: 8px 24px 10px;

          label {
            cursor: pointer;
            line-height: 48px;

            input {
              @include directional-margin(8px, initial);
              outline: none;
            }
          }
        }

        .field-edit-footer {
          height: 80px;
          padding: 16px 24px;

          .button {
            i {
              @include directional-margin(initial, -16px);
            }
          }
        }
      }

      .field-preview {
        width: 512px;
        padding: 24px 70px;
        position: relative;
        display: flex;
        justify-content: center;
        border-left: solid 1px $bluey-white;

        form {
          flex-grow: 1;

          fieldset {
            border: 0;
            padding: 16px;

            legend {
              display: 0;
            }

            .form-group * {
              width: 100%;
            }
          }

          p {
            display: 0;
          }

          input,
          textarea {
            box-shadow: 0 1px 1px 0 transparentize($black, 0.92);
            border: solid 1px $light-grey;

            border-radius: 4px;
            font-size: 12pt;
            height: 48px;

            &:hover {
              box-shadow: 0 2px 5px 0 transparentize($black, 0.99);
              transition: all 200ms ease-in-out;
            }

            &:focus {
              border-color: $blue;
              box-shadow: 0 2px 5px transparentize($dodge-blue, 0.75);
            }
          }

          textarea {
            height: 200px;
          }
        }

        .preview-label {
          height: 32px;
          width: 112px;
          border: solid 1px $bluey-white;
          border-radius: 4px;
          position: absolute;
          bottom: 24px;
          text-align: center;
          line-height: 32px;
          color: transparentize($dark-blue, 0.25);
        }
      }
    }
  }

  .add-new-field-button-container {
    border: solid 1px $bluey-white;
    height: 80px;
    padding: 16px;
  }

  .single-option {
    position: relative;

    .input-component {
      @include directional-padding(50px !important, 24px !important);

      // This is just to hack to bypass the !important overide
      &.error {
        input {
          &.input {
            &,
            &:focus {
              border-color: $fail !important;
            }
          }
        }
      }
    }

    .remove-icon {
      @include directional-position(15px, auto);
      position: absolute;
      top: 35px;
      cursor: pointer;
      font-size: 20px;
      color: $light-grey;
    }
  }

  .options-list {
    .label-option {
      font-weight: 500;
      padding: 0 25px;
    }

    .button {
      margin: 24px;
      width: calc(100% - 74px);
    }
  }

  #field-preview {
    input {
      &[type="radio"],
      &[type="checkbox"] {
        box-shadow: none;
        height: auto;
        width: auto;
        display: inline;
      }
    }

    .radio-inline,
    .checkbox-inline {
      display: block;
      margin: 20px 0;

      span {
        display: inline-block;
        margin: 0 10px;
        width: auto;
      }
    }

    select {
      margin-top: 15px;
    }
  }
}

.linear-scale-size-option {
  margin: 0 10px;
}

.option-ls {
  padding: 0 24px;
}
