@import "../../components/assets/stylesheets/global";

.heroic {
  background: $white;
  box-shadow: 0 6px 8px transparentize($grey, 0.95),
    0 1px 2px transparentize($grey, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .heroic-content {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 40px;
    padding-bottom: 0;

    h1 {
      hyphens: auto;
      font-size: 32px;
      font-weight: bold;
    }
  }
}

.section-title {
  text-transform: uppercase;
  font-weight: 500;
  color: $dark-blue;

  &.empty-state {
    font-weight: 100;
  }
}

p {
  &.description {
    padding-bottom: 40px;
  }
}

.heroic-content {
  margin-top: 40px;
}
