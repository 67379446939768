@import "../../../components/assets/stylesheets/global";

.mermaid-wrapper {
  margin: 20px;

  .mermaid {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $muted-white;
    padding: 32px;
    border-radius: 8px;
    color: transparent;
  }

  svg {
    height: auto;
  }
}

.public_funnel {
  rect {
    fill: theme("colors.warning-yellow") !important;
  }

  div {
    color: theme("colors.warning-brown") !important;
  }
}
