@font-face {
  font-family: "Dubai";
  src: url("../fonts/dubai/Dubai-Bold.eot?#iefix") format("embedded-opentype"),
    url("../fonts/dubai/Dubai-Bold.woff") format("woff"),
    url("../fonts/dubai/Dubai-Bold.ttf") format("truetype"),
    url("../fonts/dubai/Dubai-Bold.svg#Dubai-Bold") format("svg");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Dubai";
  src: url("../fonts/dubai/Dubai-Medium.eot?#iefix") format("embedded-opentype"),
    url("../fonts/dubai/Dubai-Medium.woff") format("woff"),
    url("../fonts/dubai/Dubai-Medium.ttf") format("truetype"),
    url("../fonts/dubai/Dubai-Medium.svg#Dubai-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Dubai";
  src: url("../fonts/dubai/Dubai-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../fonts/dubai/Dubai-Regular.woff") format("woff"),
    url("../fonts/dubai/Dubai-Regular.ttf") format("truetype"),
    url("../fonts/dubai/Dubai-Regular.svg#Dubai-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Dubai";
  src: url("../fonts/dubai/Dubai-Light.eot?#iefix") format("embedded-opentype"),
    url("../fonts/dubai/Dubai-Light.woff") format("woff"),
    url("../fonts/dubai/Dubai-Light.ttf") format("truetype"),
    url("../fonts/dubai/Dubai-Light.svg#Dubai-Light") format("svg");
  font-weight: 200;
  font-style: normal;
}
