@import "../../components/assets/stylesheets/global";

.search-input {
  border: solid 1px $light-grey;
  border-radius: 4px;
  width: 400px;

  .input-component {
    height: 100%;
  }

  input {
    &.input {
      box-shadow: none;
      border: 0;
      border-right: 0;
      height: 100%;

      &:hover {
        box-shadow: none;
      }
    }
  }

  .react-autosuggest__container {
    position: relative;
  }

  .react-autosuggest__input {
    width: 100%;
    padding: 10px 20px;
    font-size: 16px;
    border: solid 1px $light-grey;
    border-radius: 4px;
  }

  .react-autosuggest__input--focused {
    outline: none;
  }

  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .react-autosuggest__suggestions-container {
    display: none;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 40px;
    width: 430px;
    border: solid 1px $light-grey;
    background-color: $white;
    font-size: 16px;
    border-radius: 4px;
    z-index: 2;
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 4px 20px;
  }

  .react-autosuggest__suggestion--highlighted {
    background-color: $bluey-white;
  }
}
