@import "../../components/assets/stylesheets/global";

.user-view {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .user-avatar {
    width: 48px;
    height: 48px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: transparentize($white, 0.7);
    text-transform: uppercase;
  }

  h5 {
    margin: 0 8px;

    &.capitalize {
      text-transform: capitalize;
    }
  }
}
