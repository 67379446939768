@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.publish-modal {
  .modal-content {
    background-color: $white;
    width: 542px !important;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 0 3px 0 $blue;
    border: solid 1px $blue;
    overflow: visible;

    .publish-box {
      margin: 24px;
    }

    h6 {
      height: 32px;
      text-align: center;
      color: $dark-blue;
    }

    p {
      height: 27px;
      font-size: 14px;
      line-height: 1.93;
      letter-spacing: normal;
      text-align: center;
      color: $dark-blue;
    }

    input {
      &:first-child {
        margin-bottom: 16px;
      }
    }

    .collapsible {
      div {
        padding: 8px;
      }
    }

    .boxy {
      margin-bottom: 32px;
    }

    .embedded-input {
      display: flex;

      .embedding-instructions {
        @include directional-margin(auto, 0);
      }
    }

    .toggle-enabled {
      margin-top: 32px;

      input {
        margin-right: 8px;
      }
    }
  }
}
