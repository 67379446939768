@import "../../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.file-viewer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: opacify($black, 0.85);
  z-index: 30;
  width: 74vw;

  .file-viewer-content {
    padding: 32px;
    overflow-y: scroll;
    z-index: 1;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
    height: calc(100vh - 174px);

    &.is-pdf-content {
      justify-content: flex-start;
      height: 100vh;
      padding: 0;

      canvas {
        margin-bottom: 16px;
        box-shadow: 0 10px 30px transparentize($black, 0.98);
      }

      embed {
        width: 100%;
        height: 100%;
      }

      .pdfobject-container {
        width: 100%;
        height: calc(100vh - 174px);
      }
    }

    &.is-video-content {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 175px);

      video {
        width: calc(100vw - 32px);
        max-height: calc(100vh - 200px);
      }
    }

    img {
      box-shadow: 0 10px 30px transparentize($black, 0.98);
      transition: all 0.3s ease;
    }
  }

  .navbar {
    background-color: $dark-blue;
    color: $bluey-white;

    .navbar-item {
      color: $bluey-white;
    }
  }

  .button {
    &.is-clear {
      background-color: transparent;
      border: 0;
      font-size: 18px;
      height: auto;
      padding: 0;
      outline: none;
      color: $bluey-white;

      &:hover {
        color: $dodge-blue;
      }

      + .button {
        margin-left: 16px;
      }

      i {
        @include directional-margin(4px, initial);
      }
    }
  }

  span + .button {
    @include directional-margin(initial, 16px);
  }
}

@media only screen and (max-width: 1024px) {
  .file-viewer {
    width: 62vw;
  }
}

@media only screen and (max-width: 930px) {
  .file-viewer {
    width: 50vw;
  }
}
