@import "../../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.activity-tab {
  height: 100%;
}

#scoresList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 40px;
  height: calc(100% - 56px);

  .score-list-item {
    transition: all 0.2s ease;

    &.active {
      .item-header {
        border-radius: 8px 8px 0 0;
      }

      .item-body {
        transition: max-height 0.3s ease-out, opacity 0.6s ease-out;
        opacity: 1;
        max-height: 100%;
        border: 1px solid $bluey-white;

        .answer-value,
        .question-title {
          opacity: 1;
          transition: opacity 0.3s ease-out;
        }
      }
    }

    .item-header {
      @include directional(padding, 8px 16px 8px 10px, 8px 8px 10px 16px);
      margin-bottom: 8px;
      display: flex;
      border: 1px solid $bluey-white;
      border-radius: 8px;
      background: $muted-white;
      cursor: pointer;
      user-select: none;

      .funnel-score-container {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;

        &:focus {
          outline: none;
        }

        .funnel-name {
          @include directional-margin(auto, 0);
          @include directional-padding(4px, 0);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          flex: 67%;
          font-weight: 900;
        }

        .item-detail,
        .item-detail-group {
          display: flex;
        }

        .item-detail {
          @include directional-margin(8px, auto);
        }

        .item-detail-tag {
          @include directional-margin(4px, 0);
          font-size: 12px;
          text-transform: uppercase;
          color: $grey;
        }

        .score-value {
          font-size: 12px;
          color: $grey;
          overflow: hidden;
          max-width: 60px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .group-flex {
        flex: 90%;
      }

      .arrow-icon {
        font-size: 25px;
        color: transparentize($grey, 0.3);
        flex: 10%;
        justify-content: center;
        align-items: center;
        display: flex;
      }

      &:focus,
      &:hover {
        outline: none;
      }
    }

    .item-body {
      transition: max-height 0.3s ease-out, opacity 0.6s ease-out;
      opacity: 0;
      max-height: 0;
      padding: 8px 10px;
      margin-bottom: 8px;
      margin-top: -9px;
      width: 100%;
      overflow: hidden;
      border: 1px solid $bluey-white;

      .answer-value,
      .question-title {
        opacity: 0;
        transition: opacity 0.3s ease-out;
      }

      .item-row {
        border-bottom: 1px solid $muted-white;
        padding-bottom: 8px;
        margin-bottom: 8px;
      }

      div {
        .item-row {
          &:nth-last-of-type(1) {
            border-bottom: 0;
            padding-bottom: 0;
            margin-bottom: 0;
          }
        }
      }

      .question-title {
        font-weight: 700;
      }

      .answer-value {
        font-weight: 100;
      }

      .goto {
        font-size: 12px;
        color: $grey;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 10px;
        cursor: pointer;

        &:hover,
        &:focus {
          outline: none;
          color: $grey;
        }
      }
    }
  }

  .uppercase {
    text-transform: uppercase;
  }
}
