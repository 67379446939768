@import "../../components/assets/stylesheets/global";

.verify-banner {
  background: $success;
  font-size: 16px;
  color: $white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  padding: 10px 0;
  box-shadow: transparentize($black, 0.99) 0 2px 4px 0;

  #resend-btn {
    color: $white;
    margin: 0 40px;
    height: auto;
    text-transform: none;
    text-decoration: underline;
    font-weight: normal;

    &:hover,
    &:focus {
      background: transparent !important;
      text-decoration: none;
    }
  }
}
