@import "../../../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

#FunnelProgress {
  .funnel-progress-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    margin: 0 -8px;
  }

  // TODO: fix overflow hidden
  .progress-card {
    // overflow: hidden;
    margin: 8px;
    border-radius: 4px;
    user-select: none;
    position: relative;
    transition: all 0.2s ease;

    .progress-details {
      padding: 16px;
      padding-bottom: 8px;
      background: $white;
      box-shadow: 0 6px 8px transparentize($grey, 0.95),
        0 1px 2px transparentize($grey, 0.7);
      cursor: pointer;

      &:focus,
      &:hover {
        outline: none;
      }

      &:active {
        background: $muted-white;
        box-shadow: 0 2px 10px transparentize($grey, 0.85);
      }

      .progress-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;

        > h3 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 250px;
        }

        h3 {
          font-size: 1.3em;
          line-height: 1.3rem;
          font-weight: 400;
        }

        .progress-numbers {
          display: flex;
          align-items: flex-end;
          white-space: pre;

          h5 {
            font-size: 0.75rem;
            color: $grey;
            font-weight: 500;
          }
        }
      }

      .progress-key {
        display: flex;
        align-items: center;

        span {
          &.percentage {
            margin-left: 16px;
          }

          &.dot {
            width: 8px;
            height: 8px;
            display: inline-block;
            border-radius: 100%;
            align-items: center;
            margin: 0 8px;
            background-color: $blue;
          }
        }
      }
    }

    .progress-users-details {
      background: $white;
      width: 100%;
      top: 80px;
      z-index: 999;
      box-shadow: 0 6px 8px transparentize($dark-blue, 0.97),
        0 1px 2px transparentize($grey, 0.7);
      max-height: 0;
      transition: max-height 0.3s ease-out;
      overflow: hidden;

      &.active {
        transition: max-height 0.3s ease-in;
        max-height: 1000px;

        .user-progress,
        .empty-state {
          transition: opacity 0.3s ease-in;
          opacity: 1;
        }
      }

      .empty-state,
      .user-progress {
        opacity: 0;
        transition: opacity 0.3s ease-in;
        padding: 8px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        background: transparentize($light-grey, 0.8);
        margin: 16px;
      }

      .empty-state {
        height: 64px;
        color: $grey;
      }

      .user-avatar {
        background: $light-grey;
        width: 48px;
        height: 48px;
        font-size: 24px;
        color: transparentize($white, 0.67);
        text-transform: uppercase;
      }

      h5 {
        line-height: 1rem;
        font-size: 18px;
        color: $grey;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 180px;
      }

      h3 {
        @include directional-margin(8px, auto);
      }
    }

    .progress-bar {
      height: 4px;
    }
  }
}
