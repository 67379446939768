@import "../../components/assets/stylesheets/global";

.workspaces-container {
  margin-top: 48px;

  .workspaces-search {
    max-width: 1104px;
    margin: auto;
  }
}
