@import "../../components/assets/stylesheets/global";

.checkbox {
  font-size: 16px;
  font-weight: normal;
  color: $dark-blue;

  label {
    @include directional-padding(initial, 24px);
    position: relative;
    user-select: none;
    cursor: pointer;
    padding-left: 24px;
    color: $grey;
  }

  &.wide {
    display: block;
    width: 100%;
    height: 48px;
    line-height: 48px;
  }

  + .checkbox {
    @include directional-margin(initial, 0);
    margin-left: 0;
  }

  input {
    display: none;

    .em-doc & {
      display: initial;
    }

    &:checked ~ .checkmark {
      background-color: $blue;
      border: 0;

      &::after {
        display: block;
      }
    }
  }

  .checkmark {
    @include directional-position(auto, 0);
    position: absolute;
    transform: translateY(-50%);
    top: 47%;
    height: 16px;
    width: 16px;
    background-color: transparent;
    border: 2px solid $light-grey;
    border-radius: 4px;

    &::after {
      @include directional-position(auto, 6px);
      content: "";
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  svg {
    @include directional-position(auto, 0);
    position: absolute;
    transform: translateY(-50%);
    top: 47%;
    fill: none;
    vertical-align: middle;

    circle {
      stroke-width: 2;
      stroke: $light-grey;
    }

    path {
      stroke: $blue;
      animation: 1s infinite ease spin;

      &.outer {
        stroke-width: 2;
        stroke-dasharray: 57;
        stroke-dashoffset: 57;
      }
    }
  }

  @keyframes spin {
    0% {
      stroke-dashoffset: 57;
    }

    100% {
      stroke-dashoffset: -57;
    }
  }
}
