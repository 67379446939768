@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

#form-builder-component {
  height: auto;

  .boxy {
    padding: 0;
    height: 100%;
    box-shadow: none;

    .form-builder-header {
      height: 96px;
      padding: 40px 40px 0;
    }
  }

  .checkbox-controller {
    width: 100%;
    margin-top: 16px;

    * {
      cursor: pointer;
    }

    span {
      display: inline-block;
      margin: 0 5px;
    }
  }

  &.right-direction {
    .field-preview {
      direction: rtl;

      & .control {
        text-align: right !important;
      }
    }
  }

  &.left-direction {
    .field-preview {
      direction: ltr;

      & .control {
        text-align: left !important;
      }
    }
  }

  .logo-upload-btn {
    @include directional-margin(20px, 0);
    border: 1px solid $light-grey;
    border-radius: 4px;

    &:hover {
      background: $white;
      transform: scale(1.1);
      transition: 0.4s transform;
      box-shadow: 0 0 5px 1px $bluey-white;
    }

    .logo-img {
      display: flex;
      justify-content: center;
      height: 44px;
      width: 46px;
      text-align: center;
      cursor: pointer;

      img {
        max-height: 100%;
      }
    }

    .icon-button {
      margin: 0;
      padding: 4px 10px;
    }
  }

  .visibility-conditions {
    .Select {
      &:not(.is-open),
      .Select-control {
        box-shadow: none;
        border: solid 1px transparent;
      }

      &.is-open {
        .Select-control {
          background: $white;
        }
      }
    }

    .Select-input {
      background: transparent;
    }

    .Select-control {
      background: transparentize($black, 0.95);
    }
  }
}

.form-builder-inner-container {
  .circle-picker {
    padding: 15px 30px !important;
  }

  .title-edit-container {
    padding-top: 0 !important;
    border: solid 1px $bluey-white;
    border-bottom: 0;
  }

  .title-area {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 32px;

    .input-component {
      @include directional-margin(32px, 0);
      flex-grow: 2;

      .input {
        border: solid 1px transparent !important;
        box-shadow: none !important;
        background: transparentize($black, 0.95);
        padding: 16px;

        &:hover {
          border: 1px solid transparentize($black, 0.9) !important;
        }

        &:focus {
          border: 1px solid transparentize($black, 0.9) !important;
          outline: none !important;
          box-shadow: none;
          background: $white;
          transition: all 250ms ease-in-out !important;
        }
      }
    }
  }

  .hue-horizontal {
    border-radius: 0 !important;

    div {
      top: -6.5px;
    }
  }
}

.form-builder-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.sorting-active,
.sorting-active * {
  cursor: move !important;
}
