div {
  .icon-button {
    text-align: center;
    display: inline-block;
    cursor: pointer;
    margin: 8px;
    user-select: 0;

    .icon-button-icon {
      font-size: 24px;
      user-select: none;
    }

    .icon-button-text {
      font-weight: normal;
      font-size: 0.8125em;
      user-select: none;
    }
  }
}
