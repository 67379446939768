@import "../../components/assets/stylesheets/global";

.box-with-actions {
  div {
    &:first-child {
      padding: 24px;
      padding-bottom: 32px;
      background-color: $muted-white;

      h3 {
        font-weight: 500;
        height: 64px;
      }
    }
  }
}
