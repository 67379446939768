@import "../../components/assets/stylesheets/global";

.wallpaper {
  background: $bluey-white;
  min-height: 100vh;
  position: relative;
  padding-bottom: 80px;

  .header {
    position: relative;
    background-color: $dark-blue;
    border: 0;
    z-index: 2;

    &.transparent {
      background-color: transparent;
    }
  }

  //hack for full height. see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Box_Model/Mastering_margin_collapsing
  &::before,
  &::after {
    content: "";
    display: table;
  }

  &::after {
    clear: both;
  }
}
