@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.switch {
  display: inline-block;
  width: 60px;
  height: 34px;
  cursor: pointer;

  &.wide {
    display: flex;
    width: 100%;
    height: 48px;
    line-height: 48px;
    align-items: center;
    justify-content: flex-start;
  }

  input {
    display: none;

    &:checked + .slider {
      background-color: $blue;

      &::before {
        transform: translateX(12px);
      }
    }

    &:focus + .slider {
      box-shadow: 0 0 1px $blue;
    }
  }

  .slider {
    @include directional-margin(8px, initial);
    position: relative;
    background-color: $light-grey;
    transition: 0.2s;
    display: inline-block;
    width: 28px;
    height: 16px;

    &.round {
      border-radius: 34px;

      &::before {
        border-radius: 50%;
      }
    }

    &::before {
      position: absolute;
      content: "";
      height: 12px;
      width: 12px;
      left: 2px;
      top: 2px;
      background-color: $white;
      transition: 0.2s;
    }
  }
}
