@import "../../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.question-list {
  .btn-add-question {
    padding: 0 10px;
    border-top: 1px solid $bluey-white;
    border-radius: 0;

    &:hover {
      background-color: transparent !important;
    }
  }

  hr {
    margin: 10px 0;
  }

  > h3 + p {
    margin-bottom: 64px;
  }

  .tag {
    align-self: center;
    background-color: $bluey-white !important;
    color: $grey;
  }

  .question-list-title {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
  }

  .question-item + .question-item {
    border-top: 1px solid $bluey-white;
    padding-top: 15px;
    margin-top: 15px;
  }

  .question-wrapper {
    margin-bottom: 32px;
    padding: 0;
    border-top: 1px solid $light-grey;

    .question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $white;
      border: 1px solid $light-grey;
      border-top: 0;

      .dropdown-menu {
        @include directional-position(0, auto);
        min-width: 100px;
      }

      .ff-drag {
        background-color: $muted-white;
        border-right: 1px solid $light-grey;
        box-sizing: border-box;
        padding: 16px 8px;
      }
    }

    &.boxy {
      &.dark {
        background-color: $white;
      }
    }

    .question-content {
      width: 85%;
      padding: 0 8px;

      span {
        @include directional-margin(8px, initial);
      }
    }

    .edit-q-container {
      background-color: transparentize($light-grey, 0.7);
    }
  }

  .edit-question-title {
    margin-bottom: 32px;
    padding: 16px;

    p {
      &.light {
        margin-bottom: 16px;
      }
    }

    .input-component {
      margin-bottom: 16px;
    }
  }

  .edit-question-choices {
    padding: 16px;

    p {
      &.light {
        margin-bottom: 16px;
      }
    }

    .question-choices-header {
      display: flex;

      span {
        &:first-child {
          @include directional-margin(24px, initial);
          width: 50%;
        }

        &:nth-child(2) {
          width: 16%;
          margin-right: 16px;
        }

        &:last-child {
          width: 20%;
        }
      }
    }

    .choice {
      display: flex;
      align-items: center;
      padding-top: 8px;

      .input-component {
        &.control {
          &.f-question {
            margin-bottom: 0;
          }
        }

        &:nth-child(1) {
          @include directional-margin(24px, initial);
          width: 50%;
        }

        &:nth-child(2) {
          @include directional-margin(16px, initial);
          width: 16%;
        }
      }

      .checkbox {
        width: 20%;
      }

      .icon-button {
        color: $light-grey;
        margin: 0;

        .icon-button-icon {
          font-size: 16px;
          line-height: 32px;
        }

        &.enabled {
          &:hover {
            color: $dark-blue;
          }
        }
      }
    }

    button {
      margin-top: 8px;
    }
  }

  .edit-question-actions {
    padding: 16px 26px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: $white;

    button + button {
      @include directional-margin(initial, 8px);
    }

    .icon-button {
      @include directional-margin(initial, auto);
    }
  }
}

.sorting-active {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  border: 1px solid $light-grey;

  .dropdown-menu {
    @include directional-position(0, auto);
    min-width: 100px;
  }

  .ff-drag {
    background-color: $muted-white;
    border-right: 1px solid $light-grey;
    box-sizing: border-box;
    padding: 16px 8px;
  }

  .question-content {
    width: 85%;
    padding: 0 8px;

    span {
      @include directional-margin(8px, initial);
    }
  }
}
