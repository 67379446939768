@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import
// Unused component

.sidenav {
  height: 100%;
  background-color: $dark-blue;

  transition: all 0.3s ease-in-out;

  min-width: 256px;
  max-width: 256px;

  .collapse {
    color: $white;
  }

  .link-button {
    background-color: $blue;
    color: $white;
    margin: 24px 8px;
    text-align: center;
    border-radius: 4px;
    white-space: nowrap;
    height: 48px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      @include directional-margin(16px, 0);
      font-size: 24px;
    }

    span {
      font-size: 16px;
      font-weight: bold;
      display: inline-block;
      overflow: hidden;
    }

    &:hover {
      color: $white;
      background-color: lighten($blue, 5%);
    }

    &.active {
      background-color: $blue;

      &:hover {
        background-color: lighten($blue, 5%);
      }
    }
  }

  .user-info {
    width: 100%;
    color: $white;
    height: 48px;
    justify-content: center;

    .dropdown {
      &.settings {
        @include directional-margin(0, 16px);
        margin-left: 16px;
      }
    }

    .notifications {
      @include directional-margin(16px, 0);
    }
  }

  &.is-collapsed {
    min-width: 40px;
    max-width: 40px;

    .user-info {
      @include directional-rtl(margin-left, 0);
      flex-direction: column;
      align-items: center;

      .dropdown {
        &.settings {
          display: none;
        }
      }

      .notifications {
        margin-right: 0;
      }

      .username {
        display: none;
      }
    }

    .link-button {
      margin: 24px 0;
      border-radius: 0;

      i {
        @include directional-margin(0, initial);
      }

      span {
        width: 0;
      }
    }
  }
}

.sidenav-brand {
  height: 200px;
}

.sidenav-links {
  button {
    @include directional-txt-align;
    display: block;
    width: 100%;
    outline: none;
    border: 0;
    cursor: pointer;
  }

  a,
  button {
    transition: all 0.2s ease;
    background: transparent;
    display: block;
    color: $white;
    font-weight: 400;
    height: 48px;
    line-height: 48px;
    padding: 0 8px;

    white-space: nowrap;
    overflow: hidden;

    &:hover {
      color: $white;
      background-color: lighten($grey, 5%);
    }

    &.active {
      background-color: $grey;

      &:hover {
        background-color: lighten($grey, 5%);
      }
    }

    i {
      @include directional-margin(16px, 0);
      font-size: 24px;
    }

    span {
      font-weight: 500;
      font-size: 14px;
      vertical-align: top;
    }
  }
}
