@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

#label-menu-container {
  .blanket {
    @include directional-position(auto, 0);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
  }

  .shadow {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(transparent, $light-grey);
    height: 8px;
  }

  .label-menu {
    @include directional-position(24px, auto);
    height: 216px;
    position: absolute;
    width: 304px;
    border-radius: 4px;
    background-color: $white;
    box-shadow: 0 0 3px 0 $blue;
    z-index: 6;

    &:focus {
      outline: none;
    }
  }
}
