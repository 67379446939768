body {
  background-size: 100% 100%;
  box-sizing: border-box;
  font-size: 18px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  /* padding: 40px 0; */
  min-height: 100vh;
}
@media only screen and (max-width: 768px) {
  body {
    padding: 0;
 }
}
.em-doc {
  width: 100%;
  height: 100%;
}
.em-doc .em-header {
  width: 100%;
  height: 150px;
  position: absolute;
  top: 0;
}
.em-doc .em-body-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.em-doc .em-body {
  width: 640px;
  margin: 60px auto 20px;
  background: #fff;
  box-shadow: 0 57px 88px rgba(41, 47, 69, 0.22);
  position: relative;
}
@media only screen and (max-width: 768px) {
  .em-doc .em-body {
    width: auto;
    margin: 0;
    min-height: 100vh;
 }
}
.em-doc .em-body .logoImage {
  display: block;
  margin: 0 auto;
}
.em-doc .em-body .message-icon {
  max-width: 88px;
  max-height: 120px;
  display: block;
  margin: 0 auto 40px;
}
.em-doc .em-body .field-description {
  padding: 40px;
  font-size: 32px;
  font-weight: 800;
  width: 100%;
  text-align: center;
}
.em-doc .em-body form {
  width: 100%;
}
.em-doc .em-body form fieldset {
  border: 0;
}
.em-doc .em-body form fieldset legend {
  display: none;
}
.em-doc .em-body form fieldset .field-description {
  padding-bottom: 0;
  text-align: left;
  max-width: 60%;
}
.em-doc .em-body form fieldset .form-group .field {
  padding: 48px 48px 0;
}
.em-doc .em-body form fieldset .form-group .field .control-label {
  display: block;
  margin-bottom: 8px;
}
.em-doc .em-body form fieldset .form-group .field input, .em-doc .em-body form fieldset .form-group .field textarea {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #d3d9e1;
  border-radius: 4px;
  max-height: 48px;
  transition: all 200ms ease-in-out;
  padding: 4px 8px;
  font-size: 16px;
  width: 100%;
  height: 32px;
}
.em-doc .em-body form fieldset .form-group .field input:hover, .em-doc .em-body form fieldset .form-group .field textarea:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.01);
  transition: all 200ms ease-in-out;
}
.em-doc .em-body form fieldset .form-group .field input:active, .em-doc .em-body form fieldset .form-group .field textarea:active {
  border-color: #147cfb;
  box-shadow: 0 0 0 0.125em rgba(20, 124, 251, 0.25);
  outline: none;
  transition: all 200ms ease-in-out;
}
.em-doc .em-body form fieldset .form-group .field input:focus, .em-doc .em-body form fieldset .form-group .field textarea:focus {
  border-color: #147cfb;
  box-shadow: 0 0 0 0.125em rgba(20, 124, 251, 0.25);
  outline: none;
  transition: all 200ms ease-in-out;
}
.em-doc .em-body form fieldset .form-group .field textarea {
  min-height: 120px;
  resize: none;
}
.em-doc .em-body form fieldset .form-group .field:last-child {
  padding: 48px 48px 40px;
}
.em-doc .em-body .form-submit {
  background-color: #268aff;
  border-color: transparent;
  color: #fff;
  padding: 0 16px;
  min-width: 144px;
  height: 48px;
  border: 0;
  box-shadow: 0 1px 3px 0 rgba(41, 47, 69, 0.25);
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 auto 40px;
  display: block;
  transition: all 200ms ease-in-out;
}
.em-doc .em-body .form-submit:hover {
  background-color: #268aff;
  border-color: transparent;
  color: #fff;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
.em-doc .em-body .validation-error {
  color: #f9405d;
  font-weight: bold;
  font-size: 14px;
}
.em-doc .em-body.right-direction .form-builder {
  direction: rtl;
}
.em-doc .em-body.right-direction .form-builder .control {
  text-align: right !important;
}
.em-doc .em-body.left-direction .form-builder {
  direction: ltr;
}
.em-doc .em-body.left-direction .form-builder .control {
  text-align: left !important;
}
.em-doc .message-container {
  position: relative;
  text-align: center;
  margin-top: 30px;
  height: 60vh;
}
.em-doc .message-container img {
  max-width: 150px;
  height: auto;
}
.form-builder .select:not(.is-multiple)::after {
  top: 50% !important;
}
.form-title {
  font-weight: 100;
  font-size: 3em;
  color: #000;
  padding: 28px;
  padding-bottom: 0;
}
.disabled-sate {
  width: 15%;
  margin: 10% auto 0;
}
.disabled-sate span {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
}
.uppy-Root.uppy-DragDrop-container {
  border: 1px dashed #d3d9e1 !important;
  background: rgba(20, 124, 251, 0.01) !important;
  border-radius: 4px !important;
}
.uppy-Root.uppy-DragDrop-container .uppy-DragDrop-inner {
  padding: 30px 20px !important;
}
.uppy-Root.uppy-DragDrop-container .uppy-DragDrop-label {
  font-weight: 300;
  font-size: 22px !important;
}
.uppy-Root.uppy-DragDrop-container .uppy-DragDrop-label::after {
  content: "\F552";
  display: block;
  font-family: "Material Design Icons";
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: auto;
  transform: translate(-50%, -100%);
}
.uppy-Root.uppy-DragDrop-container .uppy-DragDrop-label .uppy-DragDrop-dragText {
  color: #000 !important;
}
.uppy-Root.uppy-DragDrop-container .UppyIcon.uppy-DragDrop-arrow {
  visibility: hidden;
}
.uppy-StatusBar {
  justify-content: space-around;
}


* {
  font-family: "Dubai", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
  scrollbar-color: #bbb #f5f5f5;
  scrollbar-width: thin;
}
*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #fff;
}
*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #bbb;
}
body {
  font-size: 16px;
}
h1 {
  font-weight: 700;
  font-size: 3.25em;
  line-height: 1.2em;
}
h2 {
  font-weight: 200;
  font-size: 2.5em;
  line-height: 1.2em;
}
h3 {
  font-weight: 500;
  font-size: 1.2em;
}
h4 {
  font-weight: 500;
  font-size: 1.75em;
}
h5 {
  font-weight: 200;
  font-size: 1.5em;
}
h6 {
  font-weight: 700;
  font-size: 1.25em;
}
p, a {
  font-weight: normal;
  font-size: 1em;
}
p.light, a.light {
  opacity: 0.3;
}
label {
  font-weight: 500;
  font-size: 1em;
}
a {
  color: #268aff;
  text-decoration: none;
}
.sub-heading {
  font-weight: 500;
  font-size: 1.125em;
}
.tiny-sub-heading {
  font-weight: 500;
  font-size: 0.875em;
}
.button-text {
  font-weight: 700;
  font-size: 1em;
  text-transform: uppercase;
}
.tiny-button-text {
  font-weight: 700;
  font-size: 0.8125em;
  text-transform: uppercase;
}
.form-error {
  font-weight: 700;
  font-size: 0.875em;
  color: #f9405d;
}
.form-value {
  font-weight: normal;
  font-size: 1em;
  color: #292f45;
}
.caption {
  font-weight: normal;
  font-size: 0.6875em;
  color: rgba(41, 47, 69, 0.5);
  margin-top: 4px;
}
.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
