@import "../../components/assets/stylesheets/global";

.thumbnail {
  display: inline-block;
  padding: 0;
  position: relative;
  transition: all 0.2s ease;
  box-shadow: 0 3px 3px transparentize($black, 0.98);
  border-radius: 4px;

  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 10px transparentize($black, 0.98);
  }

  p {
    &.file-name {
      font-size: 16px;
      color: $dark-blue;
      border: 0;
      position: absolute;
      top: 16px;
      left: 16px;
      padding: 0;
    }
  }

  i {
    height: 24px;
    width: 24px;
    font-size: 24px;
    display: block;
    text-shadow: 0 2px 2px transparentize($black, 0.98);
    z-index: 1;
    color: $grey;

    &.thumbnail-file-icon {
      position: absolute;
      bottom: 16px;
      left: 16px;
    }
  }

  &.bright {
    i {
      color: $white;
    }
  }

  button {
    &.copy {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background: $white;
      padding: 0;
      border: 0;
      position: absolute;
      top: -12px;
      left: 157px;
      box-shadow: 0 3px 3px transparentize($black, 0.98);
      direction: ltr;

      i {
        position: relative;
        left: 6px;
        top: -6px;
        color: $grey;
      }

      &:hover {
        box-shadow: 0 4px 10px transparentize($black, 0.8);
      }
    }
  }

  div {
    position: relative;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;

    canvas {
      border-radius: 4px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(
        to bottom,
        transparentize($black, 0.9),
        transparentize($black, 0.98)
      );
    }
  }
}

.directional-direction {
  button {
    .copy {
      left: auto;
      right: 157px;
    }
  }
}
