@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

#invitationEmailArea {
  .Select {
    &.is-searchable {
      &.Select--multi {
        margin-bottom: 8px;
        overflow: auto;
        height: auto;
        max-height: 128px;
        align-items: normal;

        .Select-menu-outer {
          display: none;
        }
      }
    }

    &:nth-child(2) {
      margin-bottom: 8px;
    }
  }
}

#MembersInvitationContainer {
  width: 542px;
  overflow: unset;

  .program-members-modal-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
  }

  .program-members-modal-subtitle {
    margin-bottom: 64px;
  }

  .boxy {
    padding: 24px;
  }

  .divider {
    margin-bottom: 16px;
  }

  .add-member-section {
    text-align: center;
    padding: 24px 24px 0;

    .add-member-inputs {
      @include directional-txt-align;
      margin-bottom: 8px;

      .dark {
        border: 0;
        padding: 16px;
        box-shadow: 0 1px 2px 0 transparentize($black, 0.85);
      }

      .invited-member-row {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        .invited-member-email {
          max-width: 232px;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .invited-member-role {
          @include directional-margin(unset, auto);
        }

        .Select {
          margin-bottom: 0;
          min-width: 200px;
        }
      }
    }

    .column {
      @include directional-margin(8px, 0);

      &:last-child {
        @include directional-rtl(margin-left, 0);
        margin-right: 0;
        width: 144px;
        flex: none;
      }
    }

    .label {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .invite-member {
        margin: 0;
      }

      [data-tooltip] {
        position: relative;
        z-index: 2;
        cursor: pointer;

        // Position tooltip above the element
        &::before {
          @include directional-position(-50%, unset);
          @include directional-txt-align;
          position: absolute;
          bottom: 120%;
          margin-bottom: 5px;
          padding: 7px;
          width: max-content;
          max-width: 494px;
          border-radius: 4px;
          background-color: $muted-white;
          color: $dark-blue;
          content: attr(data-tooltip);
          box-shadow: 0 4px 4px transparentize($black, 0.75);
          font-size: 12px;
          font-weight: lighter;
        }

        // Triangle hack to make tooltip look like a speech bubble
        &::after {
          position: absolute;
          bottom: 120%;
          left: 50%;
          margin-left: -5px;
          width: 0;
          border-top: 5px solid $muted-white;
          border-right: 5px solid transparent;
          border-left: 5px solid transparent;
          content: "";
          font-size: 0;
          line-height: 0;
        }

        // Hide the tooltip content by default
        &::before,
        &::after {
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }

        // Show tooltip content on hover
        &:hover {
          &::after,
          &::before {
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }
  }

  .members-section {
    .member-header {
      padding: 0;
    }

    .avatar {
      vertical-align: middle;
    }

    .member-name {
      @include directional-padding(0, 10px);
      vertical-align: middle;
    }

    .columns {
      .is-gapless {
        margin-top: 16px;
        margin-bottom: 16px;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .column + .column {
      width: 144px;
      flex: none;
    }
  }

  .members-actions {
    padding-top: 48px;
  }

  .footer-actions {
    button {
      &:last-child {
        @include directional-margin(initial, auto);
      }
    }
  }

  .button {
    &.button-component {
      min-width: 100%;
    }
  }
}
