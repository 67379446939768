@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.submissions-container {
  display: flex;
  height: calc(100vh - 48px);

  .no-submissions-found {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 64px;
    font-weight: 200;
    color: transparentize($grey, 0.65);

    span {
      margin-left: 16px;
    }
  }

  .submissions-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin-top: 40px;

    .content-header {
      .header {
        background-color: transparent;
      }

      .search-advanced {
        @include directional-position(initial, 16px);
        @include directional-rtl(width, fit-content);
        transition: all 0.3s ease;
        top: 105px;
        position: absolute;
        z-index: 10;
        background-color: $white;
        border-radius: 4px;
        box-shadow: 0 0 3px 0 $light-grey;
      }
    }

    .search {
      height: 80px;
      display: flex;
      align-items: center;
      transition: all 0.3s ease;

      .blanket {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }

      .search-input {
        position: relative;
        height: 48px;
        margin-left: 16px;
        margin-right: 16px;
        display: inline-block;

        .search-controls {
          background-color: $muted-white;
          box-shadow: 0 0 1px 0 transparentize($blue, 0.6);
          border: solid 1px $blue;
          position: absolute;
          border-radius: 4px;
          margin-top: 8px;
          padding: 16px 13px;
        }
      }

      .selected-filters {
        display: flex;
        height: 100%;
        width: 840px;
        overflow-x: scroll;
        align-items: center;
        padding: 0 2px;

        .filter {
          @include directional-margin(16px, initial);
          height: 48px;
          position: relative;

          .remove-filter {
            @include directional-position(0, auto);
            @include directional-margin(initial, 8px);
            top: 0;
            height: 100%;
            display: inline-flex;
            align-items: center;

            i {
              cursor: pointer;
            }
          }
        }
      }

      .search-control-buttons {
        @include directional-margin(24px, auto);
        display: flex;
        min-width: 150px;

        .icon-button {
          .icon-button-icon {
            i {
              color: $light-grey;
            }
          }

          .icon-button-text {
            color: $grey;
            font-size: 13px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .submissions {
    position: relative;
    width: 100%;
    display: flex;
    overflow: hidden;
    height: 100%;
    border-radius: 5px;
    border: 0;

    .single-submission {
      display: flex;
      margin-right: -100%;
      transition: all 0.3s ease;
      width: 100%;

      .submission-info {
        position: relative;
        height: 100%;
        flex-grow: 1;
        overflow-y: scroll;
        padding-left: 8px;
        padding-right: 8px;
        padding-top: 40px;
        padding-bottom: 40px;
        min-width: 460px;
        padding: 0;
        overflow-x: hidden;

        .submission-info-close {
          cursor: pointer;
          position: sticky;
          display: block;
          padding: 16px;
          width: 100%;
          height: 56px;
          border-bottom: solid 1px $bluey-white;
          top: 0;
          left: 0;

          i {
            height: 24px;
            display: block;
            line-height: 24px;
            font-size: 24px;
          }
        }

        .submission-data {
          padding: 40px;
          border-radius: 0;
          box-shadow: none;
          margin: 0;

          .submission-data-table {
            table {
              td {
                border: 0;
              }
            }
          }

          .submission-data-attachments {
            padding-top: 24px;
            overflow-y: scroll;
          }
        }

        .submitter-data {
          background-color: $muted-white;
          box-shadow: 0 6px 8px transparentize($grey, 0.95),
            0 1px 2px transparentize($grey, 0.7);
          margin: 24px;
          padding: 32px;

          .submitter-data-title {
            font-size: 18px;
            font-weight: 500;
            color: $dark-blue;
          }

          .submitter-data-table {
            margin: 0 auto;
            overflow-y: scroll;
            background-color: transparent;

            table {
              td {
                border: 0;
              }
            }
          }
        }
      }

      .scoring-pane-container {
        flex-grow: 1;
        width: 400px;
        height: 100%;
        padding: 0;
        border-left: solid 1px $bluey-white;

        .scoring-pane {
          .tabs {
            &.is-centered {
              border-bottom: solid 1px $bluey-white;
              height: 56px;
              align-items: flex-end;
              margin: 0;
            }
          }

          div {
            &#SubmissionActivities {
              padding: 40px;
              height: 100%;
            }

            &#AddComment {
              padding: 16px 24px;
              background-color: $muted-white;
              width: 100%;
              height: 176px;
            }
          }

          .Select {
            .Select-clear-zone {
              display: none;
            }
          }

          .scoring-tab {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            padding: 40px;

            .button-wrapper {
              padding-bottom: 56px 0 24px;
            }
          }
        }
      }
    }
  }

  .submission-table-container {
    flex-direction: column;
    position: relative;
    display: flex;
    max-width: 100%;
    transition: all 0.3s ease;
    background-color: $white;
    box-shadow: 0 6px 8px transparentize($grey, 0.95),
      0 1px 2px transparentize($grey, 0.7);
    min-width: 100%;

    &.collapsed {
      width: 30%;
      min-width: 30%;

      + #single-submission {
        margin-right: 0;
      }

      + .single-submission-container {
        @include directional-margin(24px, 0);
        width: 70%;
      }

      .table-header {
        background: transparent;
        box-shadow: none;
      }

      .submission-header {
        .submissions-range {
          display: none;
        }
      }
    }
  }
}

.hidden {
  display: none;
}
