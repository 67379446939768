@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.single-submission-container {
  width: 0;
  display: flex;
  transition: width 0.3s ease;
}

#SubmissionLabels {
  display: block;
}

#single-submission {
  @include directional-margin(-100%, initial);
  background: $white;
  box-shadow: 0 6px 8px transparentize($grey, 0.95),
    0 1px 2px transparentize($grey, 0.7);
  display: flex;
  transition: all 0.3s ease;
  width: 100%;
  z-index: 1;

  .submission-date {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .view-email {
    @include directional-float;
    @include directional-padding(3px 0 0 8px, 3px 8px 0 0);
    height: 20px;
    color: $blue;

    &:hover {
      background: transparent;
    }
  }

  .submission-info {
    flex-grow: 1;
    height: 100%;
    max-width: 65%;
    min-width: 460px;
    overflow: hidden;
    padding-bottom: 40px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 40px;
    padding: 0;
    position: relative;

    .selected-submission-count {
      font-size: 32px;
      padding: 24px;
    }

    .submission-info-ctrl {
      background: $white;
      border-bottom: solid 1px $bluey-white;
      display: flex;
      justify-content: flex-end;
      min-height: 56px;
      left: 0;
      padding: 4px;
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 2;

      .single-submission-header {
        display: flex;
      }

      .close-icon {
        @include directional-margin(auto, 0);
        margin-top: 0;
        margin-bottom: 0;
        font-size: 24px;

        i {
          &.mdi {
            padding: 0;
          }
        }
      }

      #assignee-menu-container {
        .blanket {
          @include directional-position(auto, 0);
          position: fixed;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
        }

        .shadow {
          position: absolute;
          bottom: 0;
          width: 100%;
          background-image: linear-gradient(transparent, $light-grey);
          height: 8px;
        }

        .assignee-menu {
          @include directional-position(24px, auto);
          height: 216px;
          margin-top: 48px;
          position: absolute;
          width: 304px;
          border-radius: 4px;
          background-color: $white;
          box-shadow: 0 0 3px 0 $blue;
          z-index: 6;

          &:focus {
            outline: none;
          }

          .Select {
            border: 0;
            border-radius: 0;
            box-shadow: none;

            .Select-control {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            .Select-menu-outer {
              min-height: 169px;

              .Select-menu {
                max-height: 169px;
                border-radius: 4px;
              }

              .Select-option {
                padding: 0;
              }
            }
          }
        }
      }
    }

    .hide-on-multiple-select {
      position: relative;

      .submission-score {
        padding: 4px 64px;
        border-bottom: 1px solid $bluey-white;
        text-align: center;
        font-size: 13px;
      }
    }

    .submission-data {
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      overflow-y: auto;
      height: calc(100vh - 206px);

      .submission-data-field {
        padding-top: 32px;
        padding: 16px 24px;
        border-radius: 8px;
        background: $muted-white;
        margin: 24px 0;

        &.directional-direction {
          direction: rtl;
        }

        &.direction-ltr {
          direction: ltr;
        }

        p {
          word-wrap: break-word;
        }

        &:first-child {
          margin-top: 0;
        }

        .copy-button {
          cursor: pointer;
        }
      }

      .submission-data-attachments {
        overflow-y: scroll;
        padding-top: 24px;
        display: flex;
        flex-wrap: wrap;

        .thumbnail {
          margin-bottom: 20px;
        }
      }

      div {
        .text-row {
          :nth-child(odd) & {
            width: 130px !important;
            margin: 0 !important;
          }

          &:nth-child(even) & {
            width: 90% !important;
            margin-top: 8px !important;
          }
        }
      }
    }

    .submitter-data {
      background-color: $muted-white;
      box-shadow: 0 6px 8px transparentize($grey, 0.95),
        0 1px 2px transparentize($grey, 0.7);
      margin: 24px;
      padding: 32px;

      .submitter-data-title {
        font-size: 18px;
        font-weight: 500;
      }

      .submitter-data-table {
        background-color: transparent;
        margin: 0 auto;
        overflow-y: scroll;

        table {
          td {
            border: 0;
          }
        }
      }
    }
  }

  .scoring-pane-container {
    @include directional-border(initial, solid 1px $bluey-white);
    flex-grow: 1;
    height: 100%;
    max-width: 35%;
    padding: 0;
    width: 400px;

    .scoring-pane {
      height: 100%;

      .tabs {
        &.is-centered {
          align-items: flex-end;
          border-bottom: solid 1px $bluey-white;
          height: 56px;
          margin: 0;
        }

        li {
          margin: 0 4px;
        }
      }

      div {
        &#SubmissionActivities {
          height: calc(100vh - 441px);
          padding: 40px;

          &.full-panel {
            height: calc(100vh - 175px);
          }
        }

        &#AddComment {
          background-color: $muted-white;
          height: 176px;
          padding: 16px 24px;
          width: 100%;
        }
      }

      .Select {
        .Select-clear-zone {
          display: none;
        }
      }

      .scoring-tab {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: 40px;
        height: calc(100% - 56px);

        #selectCreatable {
          .Select-menu-outer {
            top: auto;
          }
        }
      }
    }
  }
}
