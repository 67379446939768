@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.activity {
  border-radius: 16px 16px 16px 0;
  padding: 16px 0;
  margin-bottom: 24px;

  .divider {
    border: 0;
  }

  .activity-body {
    padding: 0 16px;
    overflow: hidden;

    p {
      @include directional-float;
      @include directional-direction;
      @include directional-txt-align;
      float: left;
    }
  }
}
