@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.advanced-search-container {
  width: 580px;

  .inputs-group {
    display: flex;
    width: 100%;
  }

  .advanced-search-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light-grey;
    padding: 0 16px;
    color: $dark-blue;

    h1 {
      font-size: 1em;
    }

    .close-icon {
      &.tiny {
        font-size: 24px;
        color: $dark-blue;
        padding: 0;

        i {
          padding: 0;
        }
      }
    }
  }

  .filter-header {
    display: flex;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 4px;

    h3 {
      width: 30%;
    }
  }

  .filter-fields,
  .action {
    padding: 16px 24px;

    h2 {
      font-size: 1em;
      font-weight: bold;
      border-bottom: 1px solid $light-grey;
      padding: 8px 0;
    }

    h3 {
      font-size: 1em;
    }

    .type {
      padding: 16px 0;
    }

    .input {
      &:focus {
        border: 1px solid $light-grey;
      }
    }

    .filter-row {
      display: flex;
      border: 0;
      padding-bottom: 16px;

      .Select-value,
      .Select-option {
        text-transform: capitalize;
      }

      .Select-clear-zone {
        display: none;
      }

      .Select-value {
        display: flex;
        align-items: center;
      }

      .filter-field {
        @include directional-margin(8px, initial);
        width: 45%;

        .Select-menu-outer {
          .Select-option {
            @include directional-padding(initial, 2em);

            &.is-disabled {
              @include directional-padding(initial, 1em);
              cursor: default;
              padding-left: 1em;
              font-weight: bold;
            }
          }
        }
      }

      .filter-operator {
        @include directional-margin(8px, initial);
        width: 20%;
      }

      .filter-value {
        @include directional-margin(16px, initial);
        width: 30%;

        .date-field {
          padding: 0 8px;
          height: 48px;
          line-height: 48px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 4px;
          width: 100%;
          outline: none;
          box-shadow: 0 1px 1px 0 transparentize($black, 0.92);
          border: solid 1px $light-grey;
          transition: all 200ms ease-in-out;

          &:hover {
            box-shadow: 0 2px 5px 0 transparentize($black, 0.9);
          }

          &:focus {
            border-color: $blue;
            box-shadow: 0 2px 5px transparentize($dodge-blue, 0.75);
          }

          &::placeholder {
            color: $dark-blue;
            opacity: 0.3;
          }
        }

        .DateInput {
          width: auto;
        }
      }

      .filter-remove {
        display: flex;
        align-items: center;
      }

      .close-icon {
        border: 0;
        background-color: transparent;
        padding: 0;
        color: $light-grey;
        font-size: 24px;
        outline: none;
        cursor: pointer;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 999px;

        &:hover {
          color: $grey;
        }

        &:active {
          color: transparentize($grey, 0.5);
        }
      }

      .Select-control {
        border-radius: 4px;
      }

      .Select-placeholder {
        opacity: 0.3;
        font-size: 16px;
        font-weight: normal;
        color: $dark-blue;
      }
    }

    .filter-controls {
      @include directional-txt-align;

      .button {
        color: $grey;
        transition: all 0.1s ease-in;

        &:hover {
          background-color: $white;
          text-decoration: underline;
        }
      }
    }
  }

  .new-filter {
    display: flex;

    .button {
      min-width: auto;
      font-size: 24px;

      i {
        padding: 0;
      }
    }

    .input-component {
      &.control {
        flex: 2;
      }
    }
  }

  .saved-filters {
    .divider {
      @include directional-txt-align;
      text-align: left;
      margin-bottom: 0;
      padding: 0;
    }

    .filter {
      @include directional-margin(16px, initial);
      position: relative;
      height: 48px;
      margin-top: 8px;
      cursor: pointer;
    }

    .input-component {
      height: 48px;
      display: inline-flex;
    }
  }

  .advanced-search-footer {
    @include directional(text-align, right, left);
    padding: 16px 24px;
    border-radius: 0 0 4px 4px;
    border-top: 1px solid $light-grey;

    .chevron-left {
      i {
        @apply text-2xl text-gray-600;
      }
    }
  }
}
