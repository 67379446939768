@import "../../../components/assets/stylesheets/global";

#logo-uploader-modal {
  .modal-content {
    & > div {
      border: solid 1px $blue;
    }
  }

  .logo-uploader-modal-content {
    padding: 24px 32px;
    width: 542px;

    h6 {
      text-align: center;
      margin-bottom: 32px;
      text-transform: uppercase;
    }

    .footer-actions {
      height: 60px;
      padding: 5px 24px;

      button {
        width: 50%;
      }
    }
  }

  .logo-container {
    height: 170px;
    text-align: center;
    padding: 0 1px;

    img {
      max-height: 100%;
    }

    .boxy {
      box-shadow: 0 2px 1px 1px $bluey-white;
    }
  }

  .boxy {
    border-radius: 0;
  }
}
