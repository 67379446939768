@import "../../components/assets/stylesheets/global";

.paginator {
  text-align: center;
  padding: 8px 16px 16px;

  .button {
    height: 24px;
    line-height: 24px;
    font-size: 13px;
    width: 24px;
    font-weight: bold;

    border-radius: 4px;
    border: 0;
    box-shadow: 0 1px 2px 0 transparentize($black, 0.85);

    color: $blue;

    &.is-info {
      color: $white;
    }

    &:first-child,
    &:last-child {
      width: auto;
      padding: 0 8px;
    }
  }

  > div {
    display: inline-block;

    + div {
      @include directional-margin(initial, 8px);
      margin-top: 8px;
    }

    &:first-child {
      @include directional-margin(initial, 0);
      margin-left: 0;
    }
  }
}
