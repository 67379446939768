@import "../../components/assets/stylesheets/global";

body {
  accent-color: $blue;
}

.form-builder {
  padding: 30px;

  .field {
    margin-bottom: 20px;

    .uploader-container {
      width: 100%;
    }

    .block {
      display: block;
      margin-bottom: 0.5em;
    }

    .radio {
      padding: 0;
      margin: 0 0 0.5em;
      display: flex;
      

      input {
        margin-top: 3px;

      }
    }
  }

  .side-padding {
    span {
      display: inline-block;
      margin: 0 7px;
    }
  }

  .divider {
    font-weight: bold;
    font-size: 20px;
    border-bottom: 1px solid $bluey-white;
    margin: 20px 0;
    min-height: 15px;
    box-shadow: 0 4px 6px -6px $light-grey;
    padding: 0;
  }

  .paragraph {
    margin: 20px 0;
  }

  .required-label {
    &::before {
      content: "*";
      color: $fail;
    }
  }

  .select {
    max-width: 200px;

    // UI fix
    &:not(.is-multiple) {
      &::after {
        top: 90%;
      }
    }
  }

  .notification {
    // UI fix
    div {
      &:not(:last-of-type) {
        border-bottom: 1px solid $fail-bg;
        margin-bottom: 15px;
        padding-bottom: 10px;
      }
    }
  }

  .rating-icons {
    font-size: 28px;
    margin: 0 10px;
    color: $light-grey;
  }

  .rating-item {
    position: relative;
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 28px;
    color: $grey;
  }

  .rating-number {
    position: relative;
    padding: 20px;
    top: -7px;
  }

  .rating-field {
    display: flex;
    align-items: center;

    &.with-normal-field > span {
      background: $white;
    }
  }

  .with-normal-field {
    input {
      visibility: hidden;
      position: absolute;
      pointer-events: none;
    }
  }

  input {
    &:required {
      box-shadow: none;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;
  }

  .dropzone {
    div {
      border: 2px dashed $light-grey;
      text-align: center;
      border-radius: 6px;
      padding-top: 10px;
      padding-bottom: 10px;

      p {
        display: inline-block;
        margin: 0 10px;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .react-datepicker__close-icon {
    width: 20px;
    height: 20px;
    position: relative;
    left: -15px;

    .right-direction & {
      right: -25px;
    }
  }

  .date-field {
    font-size: 17px;
    border: 0;
    border-bottom: 1px dashed $light-grey;
  }

  .country-city-field {
    margin-bottom: 20px;
  }

  .form-footer {
    margin-top: 48px;

    .validation-error {
      margin-top: 8px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .form-builder {
    .rating-number {
      top: -13px;
    }
  }
}

.attachment {
  font-size: 14px;
  background-color: $light-grey;
  margin-top: 5px;
  border-radius: 6px;
  padding: 10px;

  p {
    margin: 5px;
  }

  .name {
    display: inline-block;
    width: 440px;
    height: 14px;
    font-weight: bold;
  }

  .size {
    display: inline-block;
    text-align: center;
    width: 100px;
  }
}

.errors-container {
  margin: 0 24px;
}

.rejected {
  background: $fail-bg;
  color: $fail;
  border-radius: 6px;
  padding: 8px 16px;

  &:empty {
    display: none;
    padding: 0;
  }
}

.warning {
  background: $warning-bg;
  color: $warning;
  border-radius: 6px;
  padding: 8px 16px;

  &:empty {
    display: none;
    padding: 0;
  }
}

.react-datepicker__time-container {
  &.react-datepicker__time {
    &.react-datepicker__time-box {
      &ul {
        &.react-datepicker__time-list {
          padding-right: 0;
          font-size: 11px;
          font-weight: bold;

          .react-datepicker__time-list-item {
            padding: 5px 0 !important;
          }
        }
      }
    }
  }
}

.custom-date-picker {
  z-index: 999;
}

.button.is-link {
  background: $blue;
  // background: linear-gradient(90deg, rgba(94,69,255,1) 0%, rgba(0,40,179,1) 100%);

}
.button.is-link:hover {
  background: $blue;
  // background: linear-gradient(90deg, rgba(94,69,255,1) 0%, rgba(0,40,179,1) 100%);

}

[type=radio]:checked::before,
[type=checkbox]:checked::before {


  background-color: $blue;


}
