@import "../../components/assets/stylesheets/global";

.tag {
  &.filter {
    font-weight: normal;
    font-size: 1em;
    padding: 8px 16px;
    color: $dark-blue;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 transparentize($black, 0.85);
    transition: all 200ms ease-in-out;

    &.saved-filter {
      &:hover {
        transform: translateY(-2px);
        background-color: $white;

        i {
          transition: color 200ms ease-in-out;

          &:hover {
            color: $fail;
          }
        }
      }
    }

    i {
      @include directional-padding(initial, 4px);
      color: $light-grey;
    }
  }
}

.inactive-filter {
  background-color: $bluey-white;
}

.active-filter {
  background-color: $white;
}
