@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.sticky-header {
  height: 48px;
  background-color: $white;
  box-shadow: 0 1px 3px 1px transparentize($black, 0.96);
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  z-index: 2;
  justify-content: stretch;
z-index: 1000;
  .header-start {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .header-middle {
    flex: 3;
    display: flex;
    justify-content: center;
  }

  .header-end {
    display: flex;
    align-items: center;

    button {
      padding: 0.5em 1em;
      margin-bottom: -1px;
      color: transparentize($dark-blue, 0.25);
      border: 0;
      background-color: transparent;
      font-size: 1em;
      cursor: pointer;
      outline: none;
    }
  }

  .modal {
    &.is-active {
      overflow: hidden;
      direction: ltr;

      .modal-content {
        border-radius: 4px;
        background: $white;
        border: 1px solid $light-grey;
        width: 542px;
        color: $dark-blue;

        header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
          border-bottom: 1px solid $light-grey;

          .icon-button {
            margin: 0;
          }

          h1 {
            font-size: 1.5em;
          }
        }

        .body {
          padding: 16px;
          max-height: 440px;
          overflow-y: scroll;

          .v {
            margin-bottom: 24px;
            border-bottom: 1px solid transparentize($light-grey, 0.65);

            &:last-of-type {
              border: 0;
            }

            h2 {
              font-size: 1.4em;
              font-weight: 500;
              text-transform: capitalize;
              margin-bottom: 8px;
            }

            .version-date {
              // margin: 0 8px;
              font-size: 0.8em;
              opacity: 0.5;
            }

            section {
              margin-bottom: 12px;

              h3 {
                font-size: 0.9em;
                font-weight: 400;
                text-transform: uppercase;

                i {
                  font-size: 0.9em;
                }
              }

              ul {
                list-style: circle;
                margin-left: 24px;
              }
            }
          }
        }

        .icon-button {
          cursor: pointer;
        }
      }
    }
  }

  .link-button {
    min-width: 132px;
    min-height: 48px;
    background-color: $blue;
    color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;

    .razeen {
      display: inline-block;
      background: url("../assets/images/razeen.svg") no-repeat;
      width: 85px;
      height: 13px;
      background-size: contain;
    }
  }

  .tabs-container {
    display: flex;
    justify-content: center;

    .active-tab {
      a {
        color: $blue;
      }
    }
  }

  .members-button {
    width: 125px;
    margin: 0;
    justify-content: space-around;
  }

  .program-title {
    min-width: 100px;
    max-width: 300px;
    color: $dark-blue;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
    line-height: 48px;
    padding: 0 16px;
    border-right: solid 1px $light-grey;
    transition: background 0.2s ease;
    cursor: text;

    &:hover {
      background-color: transparentize($blue, 0.95);
    }
  }

  .program-title,
  .update-program-name-container {
    @include directional-border(solid 1px transparentize($light-grey, 0.5), 0);
  }

  .update-program-name-container {
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-right: solid 1px $light-grey;
  }

  .update-program-name {
    width: 120px;
    animation-name: input_width_animation;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  @keyframes input_width_animation {
    0% {
      width: 120px;
    }
    100% {
      width: 250px;
    }
  }

  .program-settings {
    .circle-button {
      width: 32px !important;
      height: 32px !important;
      margin: 0 8px;

      .circle-button-icon {
        font-size: 16px !important;
        line-height: 30px;
      }
    }

    .modal {
      z-index: 20;
    }
  }

  .user-info {
    @include directional-border(0, solid 1px transparentize($light-grey, 0.5));

    .avatar {
      height: 32px;
      width: 32px;
    }
  }

  .optional-data-container {
    display: contents;
  }
}
