@import "../../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.statistics-container {
  display: flex;

  .item {
    @include directional-padding(16px, 0);
    display: flex;
    color: $dark-blue;

    .item-title {
      @include directional-padding(8px, 0);
      text-transform: uppercase;
      font-weight: 500;
    }

    .item-value {
      text-transform: uppercase;
      font-weight: 100;
    }
  }

  .label-archived {
    background: transparentize($grey, 0.85);
    border-radius: 6px;
    padding: 0 8px;
  }
}

.group {
  display: flex;
}
