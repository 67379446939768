@import "../../components/assets/stylesheets/global";

.boxy {
  background-color: $white;
  box-shadow: 0 6px 8px transparentize($grey, 0.95),
    0 1px 2px transparentize($grey, 0.7);
  border-radius: 0;
  padding: 8px;

  &.dark {
    background-color: $muted-white;
  }

  &.cozy {
    padding: 16px;
  }

  &.comfortable {
    padding: 40px;
  }
}
