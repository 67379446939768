@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.back-to-verse {
  position: fixed;
  top: 140px;
  right: 0px;
  z-index: 1000;
  background: linear-gradient(82.99deg, #eb5a3c -11.11%, #eb5a3c 181.44%);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 0px 0px, rgba(215, 102, 42, 0.3) 5px 4px 30px 0px;
}

.blue-overlay {
  animation: blue-big 1500ms linear forwards;
  width: 5px;
  height: 5px;
  background-color: #eb5a3c;
  position: absolute;
  z-index: 100000;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}

@keyframes blue-big {
  0% {
    width: "5px";
    height: "5px";
  }
  100% {
    transform: scale(2000);
  }
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  animation: animate-in 1s ease-in-out forwards;
  /* transition: opacity 0.5s ease-in-out; */
}

@keyframes animate-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* media query */
@media only screen and (min-width: 1400px) {
  .patternMoc {
    transform: translateY(14px) !important;
  }
}
