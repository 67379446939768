@import "../../components/assets/stylesheets/global";

.Toastify__toast {
  border-radius: 4px;
  padding: 16px;
}

.Toastify__progress-bar--default {
  background: $blue;
}
