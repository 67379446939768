@import "../../components/assets/stylesheets/global";

.form-field {
  display: flex;
  border: solid 1px $bluey-white;
  border-bottom: 0;
  background-color: transparentize($white, 0.1);

  .ff-icon {
    height: 88px;
    min-width: 88px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $dodge-blue;

    .mdi {
      font-size: 18pt;
    }
  }

  .ff-body {
    width: calc(100% - 88px - 56px);
    padding: 16px;
    user-select: none;

    .ff-title {
      font-size: 18px;
      font-weight: 500;
      color: $dark-blue;
      height: 32px;
    }

    .ff-type-description {
      font-size: 13px;
      color: transparentize($dark-blue, 0.25);
      height: 24px;
    }
  }

  .ff-drag {
    padding: 24px 16px;
    font-size: 24px;
    color: $light-grey;
    transition: color 0.3s ease;

    &:hover {
      color: $dark-blue;
      cursor: grab, move;
    }
  }
}

.sortable-list-item {
  &.field-container {
    border-left: 0;
  }
}
