@import "../../components/assets/stylesheets/global";

.modal {
  .modal-content {
    width: auto;
    overflow: unset;
  }

  .modal-background {
    &.transparent {
      background-color: transparent;
    }
  }

  &.fullscreen {
    .modal-content {
      width: 100vw;
      height: 100vh;
      max-height: unset;
      overflow: auto;

      > div {
        width: 880px;
        min-height: 100vh;
        margin: 0 auto;

        > .full-width {
          width: 100%;

          .wrapper {
            width: 880px;
            margin: 0 auto;
            display: flex;
          }
        }
      }
    }

    .modal-background,
    .program-form {
      .modal-background {
        background-color: $white;
      }
    }

    .modal-background {
      .transparent {
        background-color: transparent;
      }
    }

    button {
      .close-button {
        @include directional-position(auto, 56px);
        position: fixed;
        top: 56px;
        width: 32px;
        height: 32px;
        min-width: auto;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          padding: 0 !important;
          font-size: 24px;
          line-height: 24px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          vertical-align: middle;
        }
      }
    }
  }
}
