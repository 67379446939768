#scoring-pane {
  .question {
    margin-bottom: 56px;
    direction: ltr;

    &.rtl {
      direction: rtl;

      .radiomark {
        left: auto;
        right: 0;
      }

      label {
        &.radio {
          padding-left: 0;
          padding-right: 24px;
        }
      }
    }

    p,
    .radio {
      margin-bottom: 8px;
    }
  }
}
