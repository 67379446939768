@import "../../components/assets/stylesheets/global";

button {
  &.dd-trigger {
    border: 0;
    outline: none;
    background: none;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .mdi {
      color: $light-grey;
    }

    span {
      color: $grey;
      font-size: 13px;
      font-weight: 700;
    }

    &:hover {
      .mdi {
        color: $dark-blue;
      }
    }
  }
}

.dd {
  z-index: 9999;

  .dd-menu {
    min-width: 192px;
    border-radius: 4px;
    box-shadow: 0 6px 8px transparentize($grey, 0.97);
    border: solid 1px transparentize($grey, 0.97);
    background-color: $white;
    max-height: 80vh;
    overflow: hidden auto;

    .dd-content {
      display: flex;
      flex-direction: column;

      .dd-item {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        align-items: stretch;
        font-size: 16px;
        color: $dark-blue;
        font-weight: normal;
        border-bottom: solid 1px $bluey-white;
        min-height: 48px;
        overflow: hidden;

        > .row {
          flex: 1;
          display: flex;
          flex-direction: row;
          cursor: default;
          padding: 0;
          max-width: 100%;

          > .input-component {
            flex: 999;
            transition: all 200ms ease-in-out;
          }

          &.inline-input {
            padding: 8px;
            width: 256px;

            input {
              margin-right: 8px;
            }

            button {
              min-width: 0;
              padding: 0;

              i {
                padding: 0;
              }
            }
          }

          > *:first-child {
            flex: 1;
            width: calc(100% - 64px);
          }

          &:not(.inline-input) > button {
            &:last-child {
              &:not(:first-child) {
                width: 48px;
                min-width: 0;
                transform: translateX(48px);

                i {
                  padding: 0;
                }
              }
            }
          }
        }

        > button,
        > a,
        > div,
        > label {
          cursor: pointer;
          padding: 0 16px;
          font-size: 16px;
        }

        > button {
          background: none;
          border: 0;
          outline: none;
          display: flex;
          width: 100%;
          text-align: initial;
          min-height: 48px;
          justify-content: space-between;

          &.is-text {
            color: $dark-blue;
            display: inline;

            span + span {
              color: transparentize($dark-blue, 0.45);
            }
          }
        }

        &:hover {
          background-color: transparentize($blue, 0.95);

          > div {
            .row {
              button {
                &:last-child {
                  &:not(:first-child) {
                    transform: translateX(0);
                  }
                }
              }
            }
          }
        }

        > .highlight {
          background-color: transparentize($blue, 0.95);
        }

        &:focus {
          border: 0;
          outline: none;
        }

        input {
          &.checkbox-input {
            margin-right: 8px;
            outline: none;
          }
        }

        &:first-child {
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
        }

        &:last-child {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          border-bottom: 0;
        }
      }
    }
  }
}
