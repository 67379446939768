@import "../../components/assets/stylesheets/global";

.actions-container {
  @include directional-position(initial, 450px);
  z-index: 10;
  position: absolute;
  top: 49px;
  background-color: $white;
  width: 500px;
  box-shadow: 0 0 3px 0 $light-grey;
  border-radius: 4px;

  .actions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light-grey;
    padding: 0 16px;
    color: $dark-blue;

    h1 {
      font-size: 1em;
    }

    .close-icon {
      &.tiny {
        font-size: 24px;
        color: $dark-blue;
        padding: 0;

        i {
          padding: 0;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .button {
    color: $grey;
    background-color: $white;
    box-shadow: none;
    text-shadow: none;

    &:hover {
      background-color: transparentize($blue, 0.95);
    }

    &#add-button {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .actions-list {
    padding: 24px 16px;
    overflow: scroll;
    max-height: 400px;

    .buttons {
      display: flex;
      justify-content: space-around;
      align-items: center;

      .button {
        @apply h-6 shadow-none;

        &.grey {
          background-color: $bluey-white;
          color: $grey;
          text-shadow: none;
          transition: all 0.1s ease-in;

          &:hover {
            background-color: $blue;
            color: $white;
          }
        }
      }
    }
  }
}
