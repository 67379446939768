@import "../../components/assets/stylesheets/global";

.program-wrapper {
  height: 100vh;
  margin: 0 auto;
  background: $bluey-white;
  display: flex;
  flex-direction: column;

  .container {
    width: 1104px;
    margin: 40px auto;
  }

  #program-name {
    width: 100%;
  }
}

.program-content-pane {
  transition: width 0.3s;
  margin-top: 48px;
  height: 100%;
}

.is-collapsed + .program-content-pane {
  width: calc(100vw - 40px);
}
