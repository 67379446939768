@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

#NotificationsListView {
  .notification-box {
    height: 160px;
    margin-bottom: 24px;
    background-color: $muted-white;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 transparentize($black, 0.85);

    .notification-body {
      display: flex;
      height: 96px;

      .notification-icon {
        min-width: 64px;
        padding: 16px;
        position: relative;

        i {
          position: absolute;
          top: 8px;
          font-size: 32px;
          color: $light-grey;
        }

        .avatar {
          height: 32px;
          width: 32px;
        }
      }

      .notification-data {
        width: 100%;
        position: relative;
        padding: 16px 0;

        .notification-timestamp {
          @include directional-position(16px, auto);
          position: absolute;
          top: 16px;
          font-size: 13px;
          color: transparentize($dark-blue, 0.25);
        }

        .notification-title {
          height: 32px;
          line-height: 32px;
          font-size: 18px;
          font-weight: 500;
        }

        .notification-description {
          height: 32px;
          line-height: 32px;
          font-size: 16px;
        }
      }
    }

    .notification-footer {
      height: 64px;
      background-color: $bluey-white;
      display: flex;

      .footer-link {
        height: 100%;
        width: 176px;
        padding: 8px 16px;
        display: flex;
        align-items: center;

        a {
          font-size: 16px;
          font-weight: bold;

          &:hover {
            color: $blue;
          }
        }
      }

      .goto_submission_btn {
        text-transform: none;
      }

      .footer-read {
        @include directional-margin(0, auto);
        height: 100%;
        width: 56px;
        padding: 20px 16px;

        .icon-button {
          margin: 0;
        }

        i {
          font-size: 22px;
          color: $blue;
        }
      }
    }
  }
}
