@import "../../../components/assets/stylesheets/global";

.submission-header {
  background-color: $bluey-white;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  height: 60px;
  border-bottom: 1px solid transparentize($dark-blue, 0.95);

  .actions {
    display: flex;

    .search-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: min-content;
      border: solid 1px $light-grey;
      border-radius: 4px;
      background-color: $white;

      .input-component {
        input {
          box-shadow: none;
          border: 0;
          border-right: 0;
          height: 36px;
          padding-left: 2.25em;

          &:hover {
            box-shadow: none;
          }
        }

        .icon {
          left: 0;
        }
      }

      .search-input {
        height: 36px !important;
        width: 400px;
        margin-left: 0 !important;
        margin-right: 0 !important;
        box-shadow: none;
        border: 0;
      }

      .button {
        height: 36px;
        min-width: unset;
        font-size: 20px;
        padding: 0 4px;

        i {
          padding: 0;
        }

        &.clear {
          font-size: 12px;
          background-color: $blue;
          color: $white;
          height: unset;
          padding: 0 8px;
          margin: 0 8px;

          i {
            margin: 2px;
          }
        }
      }

      .chevron-down {
        border-radius: 0;

        &:hover {
          background-color: transparentize($light-grey, 0.5) !important;
        }
      }
    }

    .button {
      &.filters-btn {
        height: 38px;
        margin: 0 8px;

        .filters-count {
          margin: 8px;
          background: $white;
          border: 1px solid transparentize($light-grey, 0.5);
          width: 24px;
          border-radius: 50%;
          height: 24px;
          display: inline-block;
          vertical-align: middle;
          padding: 2px;
        }
      }

      &.share-btn {
        height: 38px;
        padding: 0;

        i {
          font-size: 20px;
        }
      }
    }
  }

  .advanced-options {
    display: flex;
    align-items: center;

    .submissions-range {
      color: transparentize($dark-blue, 0.25);
      font-size: 14px;
      font-weight: lighter;
    }

    .button {
      font-size: 11px;
      color: $grey;
    }

    .import-modal {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: transparentize($black, 0.3);
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .uploader-container {
        width: 350px;
        background-color: $white;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        .uppy-Root {
          &.uppy-StatusBar {
            background-color: transparentize($black, 0.65);
          }
        }
      }
    }
    .export-modal {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: transparentize($black, 0.3);
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .export-container {
        background-color: $white;
        border-radius: 5px;
        padding-inline: 50px;
        padding-bottom: 80px;
        padding-top: 50px;
        .export-icon {
          width: 50px;
          height: 50px;
          margin-inline: 30px;
        }
        .export-icon:hover {
          opacity: 0.5;
          filter: invert(45%) sepia(84%) saturate(2386%) hue-rotate(195deg)
            brightness(98%) contrast(105%);
        }
        .export-icon:active {
          opacity: 0.3;
        }
        .export-text {
          padding-inline: 25px;
          padding-bottom: 40px;
          font-weight: 400;
          font-family: "Dubai", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
            "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
            "Helvetica", "Arial", sans-serif !important;
        }
      }
    }
  }
}
