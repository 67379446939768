@import "../../components/assets/stylesheets/global";

.icon-menu-component {
  .Select {
    color: $dark-blue;
    box-shadow: 0 1px 2px 0 transparentize($black, 0.85);

    .Select-control {
      display: flex;
      align-items: center;

      .Select-value {
        @include directional-padding(unset, 0);
        display: flex;
        align-items: center;
      }
    }

    .Select-menu-outer {
      .Select-option {
        padding: 0;

        &:last-child {
          .option-icon {
            border-bottom-left-radius: 4px;
          }
        }
      }
    }
  }

  .option-with-icon {
    display: flex;
    align-items: center;

    .option-icon {
      width: 48px;
      color: $blue;
      height: 48px;
      text-align: center;
      line-height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;

      img {
        height: 24px;
        width: 24px;
      }
    }

    .option-label {
      @include directional-padding(0, 8px);
    }
  }
}
