@import "../../components/assets/stylesheets/global";

.avatar {
  height: 48px;
  width: 48px;
  border-radius: 100px;
  border: 3px $white solid;
  background-color: $white;
  overflow: hidden;
  box-shadow: 0 2px 4px 0 transparentize($black, 0.92);
}
