@import "../../../../components/assets/stylesheets/global";

.fields_list {
  .field-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid $light-grey;

    .checkbox {
      padding: 8px;
      width: 100%;
    }

    .ff-drag {
      background-color: $muted-white;
      box-sizing: border-box;
      padding: 16px 8px;
      border-right: 1px solid $light-grey;
      cursor: grab;
      align-self: stretch;
      display: flex;
      align-items: center;

      .mdi {
        &::before {
          align-self: center;
        }
      }
    }
  }

  .boxy {
    padding: 0;
    max-height: 300px;
    min-height: 300px;
    overflow: auto;
  }
}

.sorting-active {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $light-grey;

  .checkbox {
    padding: 8px;
    width: 100%;
  }

  .ff-drag {
    background-color: $muted-white;
    box-sizing: border-box;
    padding: 16px 8px;
    border-right: 1px solid $light-grey;
  }
}
