@import "../../components/assets/stylesheets/global";

.group-option {
  display: flex;
  height: 48px;
  align-items: center;
  padding: 8px;

  .group-option-icon {
    padding: 8px;
  }
}

.filterer-option {
  display: flex;
  height: 48px;
  align-items: center;
  padding: 8px;

  .avatar {
    height: 24px;
    width: 24px;
    border: 1px $white solid;
    box-shadow: 0 2px 4px 0 transparentize($black, 0.92);
    margin-right: 8px;
  }
}
