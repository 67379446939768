@import "../../components/assets/stylesheets/global";

.footer-actions {
  padding: 16px 24px;
  height: 80px;
  background-color: $bluey-white;
  display: flex;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
