@import "../../../components/assets/stylesheets/global";

.reason {
  .reason-indicator {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $grey;
    justify-content: flex-end;
    margin: 4px 0 0;

    .loader {
      margin-right: 4px;
    }

    button {
      border: 0;
      padding: 0;
      font-size: 14px;
      margin-left: 4px;
      color: $dodge-blue;
      border-bottom: 2px solid transparentize($dodge-blue, 0.98);
      outline: none;
    }

    i {
      margin-right: 4px;
    }
  }

  textarea {
    &.input {
      transition: all 0.3s ease;

      &:not(:empty) {
        border: 1px solid $bluey-white;
        box-shadow: none;
        resize: none;
        text-decoration-style: dotted;
        color: $dark-blue;
      }

      &:empty,
      &:focus {
        border: 1px solid $light-grey;
        box-shadow: inset 0 1px 2px transparentize($black, 0.92);
        resize: vertical;
      }

      &:focus {
        border-color: $dodge-blue;
      }
    }
  }
}
