@import "../../../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.input_sources {
  .operator-dropdown {
    @include directional-txt-align;
    @include directional-margin(initial, auto);
    width: 72px;
    background-color: $bluey-white;
    color: $dark-blue;
    padding-right: 8px;
    position: relative;
    bottom: 14px;

    .Select {
      box-shadow: none;
      border: 0;
      height: 38px;

      .Select-control {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .boxy {
    padding: 16px;
    margin-bottom: 20px;
  }

  .input_source_select {
    display: flex;
    margin-bottom: 16px;

    .Select {
      margin: 0 20px;
      flex: 1;
    }

    label {
      color: $grey;
      display: flex;
      align-items: center;
      flex: 1;
    }

    .icon-button {
      @include directional-margin(0, auto);
    }
  }

  .filter-fields {
    .inputs-group {
      display: block;
      width: 100%;
    }

    .filter-row {
      display: flex;
      border: 0;
      padding: 16px 0;
      border-top: 1px solid $bluey-white;

      .inputs-group {
        width: 100%;
      }

      .Select-value,
      .Select-option {
        text-transform: capitalize;
      }

      .Select-clear-zone {
        display: none;
      }

      .Select-value {
        display: flex;
        align-items: center;
      }

      .filter-field {
        @include directional-margin(8px, initial);
        flex: 1;
        height: 48px;

        .Select-menu-outer {
          .Select-option {
            @include directional-padding(initial, 2em);

            &.is-disabled {
              @include directional-padding(initial, 1em);
              cursor: default;
              font-weight: bold;
            }
          }
        }
      }

      .filter-operator {
        @include directional-margin(8px, initial);
        height: 48px;
      }

      .filter-value {
        @include directional(margin-right, 8px, initial);
        @include directional-rtl(margin-left, 16px);
        height: 48px;

        .date-field {
          padding: 0 8px;
          height: 48px;
          line-height: 48px;
          font-size: 16px;
          font-weight: 400;
          border-radius: 4px;
          width: 100%;
          outline: none;
          box-shadow: 0 1px 1px 0 transparentize($black, 0.92);
          border: solid 1px $light-grey;
          transition: all 200ms ease-in-out;

          &:hover {
            box-shadow: 0 2px 5px 0 transparentize($black, 0.99);
          }

          &:focus {
            border-color: $blue;
            box-shadow: 0 2px 5px transparentize($dodge-blue, 0.75);
          }

          &::placeholder {
            color: $dark-blue;
            opacity: 0.3;
          }
        }

        .DateInput {
          width: auto;
        }
      }

      .filter-remove {
        display: flex;
        align-items: center;
      }

      .close-icon {
        border: 0;
        background-color: transparent;
        padding: 0;
        color: $light-grey;
        font-size: 24px;
        outline: none;
        cursor: pointer;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 999px;

        &:hover {
          color: $light-grey;
        }

        &:active {
          color: transparentize($grey, 0.5);
        }
      }

      .Select-control {
        border-radius: 4px;
      }

      .Select-placeholder {
        opacity: 0.3;
        font-size: 16px;
        font-weight: normal;
        color: $dark-blue;
      }
    }

    .filter-controls {
      @include directional-txt-align;

      .tiny {
        &:hover {
          background-color: $white;
        }
      }
    }
  }
}
