@import "../../components/assets/stylesheets/global";

.comment-outer-wrapper {
  @include directional-rtl(direction, ltr);
  margin-bottom: 24px;

  &:hover {
    .delete-button {
      display: block;
    }
  }

  &.blue {
    direction: rtl !important;

    .comment {
      background-color: transparentize($blue, 0.95);
      border-radius: 16px 16px 0;
    }
  }

  .comment {
    border: solid 1px transparentize($dark-blue, 0.95);
    padding: 8px 0;
    background-color: transparentize($dark-blue, 0.95);
    line-height: 13px;
    border-radius: 0 16px 16px;
    width: fit-content;
  }

  .comment-footer {
    span {
      font-size: 11px;
      color: transparentize($black, 0.45);
    }
  }

  .delete-button {
    display: none;

    .icon-button-icon {
      font-size: 20px;
      color: $grey;
    }
  }

  .divider {
    border: 0;
  }

  .comment-body {
    padding: 0 16px;
    display: inline-block;

    .avatar {
      @include directional-float;
      width: 32px;
      height: 32px;
    }

    .comment-text {
      @include directional(padding, 3px 0 0 8px, 3px 8px 0 0);
      @include directional-float;
      white-space: normal;
      overflow: visible;
    }
  }
}
