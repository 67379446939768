@import "../../components/assets/stylesheets/global";

#form-builder-container {
  height: auto;

  .boxy {
    padding: 0;
    height: 100%;

    .form-builder-header {
      height: 96px;
      padding: 40px 40px 0;
    }
  }
}

.form-builder-inner-container {
  .circle-picker {
    padding: 15px 30px !important;
  }

  .title-edit-container {
    padding-top: 0 !important;
  }

  .title-area {
    margin-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }

  .editable-header-content {
    background-color: $muted-white;
  }
}

.form-builder-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .publish-icon {
    color: $blue;
  }
}

.sorting-active {
  z-index: 999;
  box-shadow: 0 8px 32px transparentize($black, 0.95);
}

.sorting-active,
.sorting-active * {
  cursor: grabbing !important;
  pointer-events: all !important;
}
