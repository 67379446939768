@import "../../components/assets/stylesheets/global";

#ProgramMembersModal {
  > .boxy {
    margin: 10vh auto;
    padding: 40px;
    border-radius: 4px;
    background: $bluey-white;

    .invite-member {
      text-align: left;
      margin-top: 24px;
      margin-bottom: 8px;
    }
  }
}
