@import "../../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.funnel_tabs {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 43px;
  background-color: transparentize($muted-white, 0.05);

  li {
    display: flex;
    justify-content: center;
    align-items: center;

    a {
      padding: 0 1.2em;
    }

    button {
      &.button {
        &.funnel_settings-btn {
          min-width: auto;
          margin: 0;
          padding: 0;
          height: 30px;
          width: 24px;
          color: $grey;

          &.active {
            i {
              color: $blue;
            }
          }

          i {
            @include directional(padding, 0 0 0 4px, 0 4px 0 0);
            color: $grey;
            font-size: 16px;
            margin: 0;
          }

          &:hover,
          &:active,
          &:focus {
            background: transparent !important;

            i {
              color: $blue;
            }
          }
        }
      }
    }
  }

  &.tabs {
    ul {
      li {
        a {
          border-bottom: 0 !important;
          padding: 0 8px;
        }

        &.active-funnel {
          a {
            @include directional(padding-right, 0, 8px);
            @include directional-rtl(padding-left, 4px);
          }
        }

        &:hover {
          background: $bluey-white;
          transition: 0.3s;

          a {
            color: $blue;
          }
        }
      }
    }
  }

  .tabs {
    overflow: hidden;
  }

  #funnelsTabContainer {
    position: relative;
    overflow: hidden;
    overflow-x: auto;
    width: calc(100vw - 102px);
    display: flex;

    > div {
      &:last-of-type {
        padding-right: 24px;
      }
    }
  }

  .add_funnel-btn {
    i {
      font-size: 18px;
      color: $light-grey;

      &:hover {
        color: $blue;
        transition: 0.3s;
      }
    }
  }

  .funnels-dropdown-menu,
  .add_funnel-btn {
    border-radius: 0;
    padding: 0 16px;

    &:hover {
      color: $blue !important;
      background-color: transparentize($light-grey, 0.5) !important;
    }
  }

  .funnels-dropdown-menu {
    .mdi {
      padding: 0 !important;
      font-size: 20px;
    }
  }

  button {
    &.button {
      &.add_funnel-btn {
        color: $grey;

        i {
          color: $grey;
          padding: 0 !important;
        }

        &:hover {
          i {
            color: $blue;
          }
        }
      }
    }
  }

  &_list {
    display: flex;
  }
}

.funnel-sorting-active {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 999;
  white-space: nowrap;

  a {
    padding: 0 8px;
    font-size: 13px;
    font-weight: bold;
    color: $grey;
  }

  .funnel_settings-btn {
    display: none;
  }
}

.funnel-sorting-active,
.funnel-sorting-active * {
  cursor: grabbing !important;
  pointer-events: all !important;
}

.funnels-dropdown {
  left: 40px !important;
  box-shadow: 0 0 3px 0 $light-grey;

  .active-funnel {
    color: $blue;
  }

  .dd-item {
    min-height: 16px !important;

    a {
      width: 100%;
      padding: 4px 8px !important;
    }
  }
}
