@import "../../components/assets/stylesheets/_global";

.spinner-container {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10000;
  background: transparentize($white, 0.38);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  width: 70px;
  text-align: center;

  > div {
    width: 18px;
    height: 18px;
    background-color: $blue;
    border-radius: 100%;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    animation-delay: -0.32s;
  }

  .bounce2 {
    animation-delay: -0.16s;
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

.spinner-small {
  span {
    opacity: 0.5;
  }

  svg {
    margin: auto 8px;
    animation: rotator 1.4s linear infinite;
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(270deg);
    }
  }

  .path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash 1.4s ease-in-out infinite,
      colors (1.4s * 4) ease-in-out infinite;
  }

  @keyframes colors {
    0% {
      stroke: $dodge-blue;
    }
    100% {
      stroke: $blue;
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: 187;
    }

    50% {
      stroke-dashoffset: calc(187 / 4);
      transform: rotate(135deg);
    }

    100% {
      stroke-dashoffset: 187;
      transform: rotate(450deg);
    }
  }
}
