@import "../../components/assets/stylesheets/global";

#field-container {
  .add-new-field {
    .boxy {
      padding: 0;
      display: flex;

      .field-edit {
        width: 512px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .field-edit-body {
          .input-component {
            padding: 24px 24px 0;
          }

          .icon-menu-component {
            padding: 24px;
          }

          .divider {
            margin: 0;
          }

          .required-checkbox {
            padding: 8px 24px 32px;

            label {
              cursor: pointer;
              line-height: 48px;

              input {
                margin-right: 8px;
                outline: none;
              }
            }
          }
        }

        .field-edit-footer {
          height: 80px;
          padding: 16px 24px;

          .button {
            i {
              margin-left: -16px;
            }
          }
        }
      }

      .field-preview {
        width: 512px;
        padding: 24px 88px;
        position: relative;
        display: flex;
        justify-content: center;

        form {
          flex-grow: 1;

          fieldset {
            border: 0;
            padding: 16px;

            legend {
              display: none;
            }

            .form-group * {
              width: 100%;
            }
          }

          p {
            display: none;
          }

          input,
          textarea {
            box-shadow: 0 1px 1px 0 transparentize($black, 0.92);
            border: solid 1px $light-grey;

            border-radius: 4px;
            font-size: 12pt;
            height: 48px;

            &:hover {
              box-shadow: 0 2px 5px 0 transparentize($black, 0.99);
              transition: all 200ms ease-in-out;
            }

            &:focus {
              border-color: $blue;
              box-shadow: 0 2px 5px transparentize($dodge-blue, 0.75);
            }
          }

          textarea {
            height: 200px;
          }
        }

        .preview-label {
          height: 32px;
          width: 112px;
          border: solid 1px $light-grey;
          border-radius: 4px;
          position: absolute;
          bottom: 24px;
          text-align: center;
          line-height: 32px;
          color: transparentize($dark-blue, 0.25);
        }
      }
    }

    .field-edi {
      .react-datepicker-wrapper {
        margin-top: 15px;
        width: 70%;

        input {
          padding: 5px;
          width: 100%;
          font-size: 13px;
        }

        .react-datepicker__input-container {
          width: 100%;
        }

        .react-datepicker__close-icon {
          width: 20px;
          height: 20px;
          position: absolute;
          right: 0;
          top: 10px;
        }
      }
    }
  }

  .add-new-field-button-container {
    padding: 16px 40px;

    .add-new-field-button {
      height: 80px;
      background-color: $muted-white;
      border-radius: 4px;
      box-shadow: 0 1px 2px 0 transparentize($black, 0.85);
      padding: 16px 24px;
      cursor: pointer;
    }
  }

  .single-option {
    position: relative;

    .input-component {
      padding-right: 50px !important;
    }

    .remove-icon {
      position: absolute;
      top: 35px;
      right: 15px;
      cursor: pointer;
      font-size: 20px;
      color: $light-grey;
    }
  }

  .options-list {
    .label-option {
      font-weight: 500;
      padding: 0 25px;
    }

    .is-white {
      &.add-op {
        margin-top: 20px;
      }
    }
  }

  #field-preview {
    input {
      &[type="radio"],
      &[type="checkbox"] {
        box-shadow: none;
        height: auto;
        width: auto;
      }
    }

    .radio-inline,
    .checkbox-inline {
      display: block;
      margin: 20px 0;

      span {
        display: inline-block;
        margin: 0 10px;
        width: auto;
      }
    }

    select {
      margin-top: 15px;
    }
  }
}

html [lang="ar"] {
  .field-edit {
    .react-datepicker__close-icon {
      left: 10px;
      right: auto !important;
    }
  }
}
