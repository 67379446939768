@import "../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.dd-item {
  label {
    &.radio {
      &.wide {
        @include directional-margin(initial, 16px);
        @include directional-padding(initial !important, 24px !important);
      }
    }
  }
}

label {
  &.radio {
    font-size: 16px;
    font-weight: normal;
    position: relative;
    user-select: none;
    cursor: pointer;
    padding-left: 24px;
    color: $grey;

    .rtl & {
      padding-left: initial;
      padding-right: 24px;
    }

    &.wide {
      display: flex;
      align-items: center;
    }

    + label {
      &.radio {
        margin-left: 0;

        .rtl & {
          margin-left: initial;
          margin-right: 0;
        }
      }
    }

    .radiomark {
      position: absolute;
      left: 0;
      height: 16px;
      width: 16px;
      background-color: transparent;
      border-radius: 50%;
      border: 2px solid $light-grey;

      .rtl & {
        left: auto;
        right: 0;
      }

      &::after {
        @include directional-position(auto, 2px);
        content: "";
        position: absolute;
        display: none;
        top: 2px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $blue;
      }
    }

    input {
      display: none;

      .em-doc & {
        display: initial;
      }

      &:checked {
        ~ .radiomark {
          border-color: $blue;

          &::after {
            display: block;
          }
        }

        ~ .checkmark {
          background-color: transparent;
          border: 0;

          &::after {
            display: block;
          }
        }
      }

      &:hover {
        &:checked {
          ~ .checkmark {
            &::after {
              border: 0;
              content: "\D7";
              transform: rotate(0);
              line-height: 8px;
              font-size: 26px;
              left: -5px;
              top: -1px;
              color: $fail;
              transition: color 0.3s ease;
            }
          }
        }
      }
    }

    .checkmark {
      @include directional-position(auto, 0);
      position: absolute;
      transform: translateY(-50%);
      top: 47%;
      height: 4px;
      width: 4px;
      background-color: $light-grey;
      border-radius: 4px;

      &::after {
        position: absolute;
        display: none;
        content: "\D7";
        top: -4px;
        width: 6px;
        height: 14px;
        border: solid $blue;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        color: transparent;
        transition: border-color 0.3s ease;
      }
    }

    svg {
      position: absolute;
      left: 0;
      fill: none;

      .rtl & {
        right: 0;
      }

      circle {
        stroke-width: 2;
        stroke: $light-grey;
      }

      path {
        stroke: $blue;
        animation: 1s infinite ease spin;

        &.outer {
          stroke-width: 2;
          stroke-dasharray: 57;
          stroke-dashoffset: 57;
        }
      }
    }

    @keyframes spin {
      0% {
        stroke-dashoffset: 57;
      }

      100% {
        stroke-dashoffset: -57;
      }
    }
  }
}
