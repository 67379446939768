$black: #000;
$white: #fff;
$blue: #268aff;
$blue-sky: #1283da;
$dodge-blue: #147cfb;
$dark-blue: #292f45;
$success: #1ecf76;
$fail: #f9405d;
$warning: #c9a62c;
$warning-bg: #fffbed;
$fail-bg: #fff7f9;
$grey: #888fa7;
$light-cyan: #d7e9ff;
$light-grey: #d3d9e1;
$bluey-white: #f4f7fb;
$muted-white: #fbfbfb;
$scrollbar-track: #f5f5f5;
$scrollbar-thumb: #bbbbbb;

$THEME: tam;

@if $THEME == moc {
  $black: #000;
  $white: #fff;
  $blue: #eb5a3d;
  $blue-sky: #eb5a3c;
  $dodge-blue: #eb5a3c;
  $dark-blue: #292f45;
  $success: #1ecf76;
  $fail: #f9405d;
  $warning: #fdb236;
  $warning-bg: #fffbed;
  $fail-bg: #fff7f9;
  $grey: #888fa7;
  $light-cyan: #d7e9ff;
  $light-grey: #d3d9e1;
  $bluey-white: #eeeeee;
  $muted-white: #fbfbfb;
  $scrollbar-track: #f5f5f5;
  $scrollbar-thumb: #bbbbbb;
} @else {
  $black: #000;
  $white: #fff;
  $blue: #268aff;
  $blue-sky: #1283da;
  $dodge-blue: #147cfb;
  $dark-blue: #292f45;
  $success: #1ecf76;
  $fail: #f9405d;
  $warning: #c9a62c;
  $warning-bg: #fffbed;
  $fail-bg: #fff7f9;
  $grey: #888fa7;
  $light-cyan: #d7e9ff;
  $light-grey: #d3d9e1;
  $bluey-white: #f4f7fb;
  $muted-white: #fbfbfb;
  $scrollbar-track: #f5f5f5;
  $scrollbar-thumb: #bbbbbb;
}

@mixin directional($property, $ltr-value, $rtl-value) {
  #{$property}: $ltr-value;

  [dir="rtl"] & {
    #{$property}: $rtl-value;
  }
}

@mixin directional-rtl($property, $rtl-value) {
  [dir="rtl"] & {
    #{$property}: $rtl-value;
  }
}

@mixin directional-margin($margin-right, $margin-left) {
  margin-right: $margin-right;
  margin-left: $margin-left;

  [dir="rtl"] & {
    margin-right: $margin-left;
    margin-left: $margin-right;
  }
}

@mixin directional-border($border-right, $border-left) {
  border-right: $border-right;
  border-left: $border-left;

  [dir="rtl"] & {
    border-right: $border-left;
    border-left: $border-right;
  }
}

@mixin directional-padding($padding-right, $padding-left) {
  padding-right: $padding-right;
  padding-left: $padding-left;

  [dir="rtl"] & {
    padding-right: $padding-left;
    padding-left: $padding-right;
  }
}

@mixin directional-position($right, $left) {
  right: $right;
  left: $left;

  [dir="rtl"] & {
    right: $left;
    left: $right;
  }
}

@mixin directional-direction {
  @include directional(direction, ltr, rtl);
}

@mixin directional-txt-align {
  @include directional(text-align, left, right);
}

@mixin directional-float {
  @include directional(float, left, right);
}
