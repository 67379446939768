@import "../../components/assets/stylesheets/global";

.chipp {
  font-size: 0.8125em !important;
  font-weight: bold;
  padding: 0 8px !important;
  border-radius: 30px !important;
  background: $bluey-white !important;

  i {
    font-size: 24px;

    &:first-child {
      padding-right: 4px;
    }

    &:last-child {
      padding-left: 4px;
    }
  }
}

.is-notifier {
  color: $white !important;
  background-color: $success !important;
}
