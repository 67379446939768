@import "../../components/assets/stylesheets/global";

.Select {
  box-shadow: 0 1px 1px 0 transparentize($black, 0.92);
  border: solid 1px $light-grey;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 48px;
  font-weight: 400;
  transition: all 200ms ease-in-out;

  &:hover,
  &:focus,
  &.is-open {
    box-shadow: 0 2px 5px 0 transparentize($black, 0.99);
    border: solid 1px $light-grey;
  }

  .Select-option {
    font-size: 16px;
    color: $dark-blue;

    &.is-focused {
      background-color: $muted-white;
      color: $dark-blue;
    }
  }
}

.Select-control {
  border: 0;
  border-radius: 4px;
  height: 46px;
}

.Select-placeholder {
  opacity: 0.3;
  font-size: 16px;
  font-weight: normal;
  color: $dark-blue;
  display: flex;
  align-items: center;
}

.Select-menu-outer {
  box-shadow: 0 1px 2px 0 transparentize($black, 0.85);
  border-radius: 0 0 4px 4px;
  background-color: $white;
  border: 0;
}

.Select-value-label {
  vertical-align: text-top;
}

.Select--multi .Select-value {
  background-color: rgba(0, 40, 179, .3);
 
  color: #0028B3;
}