@import "../../components/assets/stylesheets/global";

.share-funnel-container {
  @include directional-position(initial, 584px);
  z-index: 10;
  position: absolute;
  top: 49px;
  background-color: $white;
  width: 500px;
  box-shadow: 0 0 3px 0 $light-grey;
  border-radius: 4px;

  .share-funnel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $light-grey;
    padding: 0 16px;
    color: $dark-blue;

    h1 {
      font-size: 1em;
    }

    .close-icon {
      &.tiny {
        font-size: 24px;
        color: $dark-blue;
        padding: 0;

        i {
          padding: 0;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .share-funnel-wrapper {
    padding: 24px 16px;
  }

  .input-component {
    input {
      &:focus {
        @apply border-gray-400 shadow-none;
      }
    }
  }
}
