@import "../../../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

.users_list {
  background-color: $white;

  .boxy {
    padding: 0;
  }

  .avatar {
    height: 32px;
    width: 32px;
  }

  .assignment_item {
    display: flex;
    cursor: pointer;
    position: relative;
    border-bottom: 1px solid $light-grey;

    &.selected {
      background: transparentize($blue, 0.95);

      &::after {
        @include directional-position(16px, auto);
        content: "";
        top: 13px;
        width: 8px;
        height: 16px;
        border: solid $blue;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: absolute;
      }
    }
  }

  .user_item {
    padding: 8px;
  }

  .group_item {
    padding: 8px 12px;

    i {
      font-size: 25px;
      color: $light-grey;
    }
  }

  .item_name {
    margin: 0 10px;
    align-self: center;
  }

  .no-members {
    display: flex;
    height: 300px;
    justify-content: center;
    align-items: center;
  }
}
