@import "./fonts.css";
@import "global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

* {
  font-family: "Dubai", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial",
    sans-serif !important;
  // sass-lint:disable no-misspelled-properties
  // Firefox scrollbars style
  scrollbar-color: $scrollbar-thumb $scrollbar-track;
  scrollbar-width: thin;
  // sass-lint:enable no-misspelled-properties
}

// sass-lint:disable no-vendor-prefixes
// Chrome scrollbars style
*::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: $scrollbar-track;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: $white;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $scrollbar-thumb;
}
// sass-lint:enable no-vendor-prefixes

body {
  font-size: 16px;
}

h1 {
  font-weight: 700;
  font-size: 3.25em;
  line-height: 1.2em;
}

h2 {
  font-weight: 200;
  font-size: 2.5em;
  line-height: 1.2em;
}

h3 {
  font-weight: 500;
  font-size: 1.2em;
}

h4 {
  font-weight: 500;
  font-size: 1.75em;
}

h5 {
  font-weight: 200;
  font-size: 1.5em;
}

h6 {
  font-weight: 700;
  font-size: 1.25em;
}

p,
a {
  font-weight: normal;
  font-size: 1em;

  &.light {
    opacity: 0.3;
  }
}

label {
  font-weight: 500;
  font-size: 1em;
}

a {
  color: $blue;
  text-decoration: none;
}

.sub-heading {
  font-weight: 500;
  font-size: 1.125em;
}

.tiny-sub-heading {
  font-weight: 500;
  font-size: 0.875em;
}

.button-text {
  font-weight: 700;
  font-size: 1em;
  text-transform: uppercase;
}

.tiny-button-text {
  font-weight: 700;
  font-size: 0.8125em;
  text-transform: uppercase;
}

.form-error {
  font-weight: 700;
  font-size: 0.875em;
  color: $fail;
}

.form-value {
  font-weight: normal;
  font-size: 1em;
  color: $dark-blue;
}

.caption {
  font-weight: normal;
  font-size: 0.6875em;
  color: transparentize($dark-blue, 0.5);
  margin-top: 4px;
}

.inline-p {
  p {
    @include directional-margin(32px, initial);
    display: inline-block;
  }
}

.ellipses {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

blockquote {
  font-size: 1.5rem;
  max-width: 600px;
  line-height: 1.4;
  position: relative;
  margin: 0;
  padding: 0.5rem;
  margin-left: 26px;

  &::before,
  &::after {
    position: absolute;
    color: $light-grey;
    font-size: 8rem;
    width: 4rem;
    height: 4rem;
    font-weight: 100;
  }

  &::before {
    @include directional(content, "“", "”");
    left: -3rem;
    top: -2rem;
  }

  &::after {
    @include directional(content, "”", "“");
    right: -4rem;
    bottom: 1rem;
  }
}

table {
  td,
  th {
    @include directional-txt-align;
  }
}

.control {
  @include directional-txt-align;

  &.has-icons-right {
    .input,
    .select {
      select {
        @include directional-rtl(padding-right, calc(0.625em - 1px));
        @include directional-rtl(padding-left, 2.25em);
      }
    }

    .icon {
      &.is-right {
        @include directional-position(0, auto);
      }
    }
  }
}

.select {
  select {
    &:not([multiple]) {
      @include directional-rtl(padding-right, calc(0.625em - 1px));
      @include directional-rtl(padding-left, 2.5em);
    }
  }

  &:not(.is-multiple) {
    &::after {
      @include directional-rtl(left, 1.125em);
      @include directional-rtl(right, auto);
    }
  }
}

.uppy-Root {
  &.uppy-DragDrop-container {
    border: 1px dashed $light-grey !important;
    background: transparentize($dodge-blue, 0.99) !important;
    border-radius: 4px !important;

    .uppy-DragDrop-inner {
      padding: 30px 20px !important;
    }

    .uppy-DragDrop-label {
      &::after {
        content: "\F552";
        display: block;
        font-family: "Material Design Icons";
        font-size: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: auto;
        transform: translate(-50%, -100%);
      }
    }

    .UppyIcon {
      &.uppy-DragDrop-arrow {
        visibility: hidden;
      }
    }

    .uppy-DragDrop-dragText {
      color: $black !important;
    }
  }
}

//Tablet overrides
@media only screen and (max-width: 1024px) {
  .submissions-container {
    .submission-table-container {
      .submissions-table {
        height: calc(100vh - 150px) !important;
      }

      &.collapsed {
        width: 25% !important;
        min-width: 25% !important;

        .submissions-table {
          height: calc(100vh - 91px) !important;
        }
      }

      &.collapsed + .single-submission-container {
        width: 75% !important;
      }
    }

    .submissions {
      .single-submission {
        .submission-info {
          .submission-data {
            padding: 16px !important;
          }
        }
      }
    }
  }

  .submissions-table {
    .table-header {
      justify-content: stretch !important;

      .table-cell {
        width: 100px !important;
        min-width: 100px !important;
        max-width: 100px !important;

        &.checkbox-cell {
          width: 60px !important;
          min-width: 60px !important;
          max-width: 60px !important;
        }

        &.sortable {
          padding: 0 !important;
        }

        &.id-cell {
          width: 60px !important;
          min-width: 60px !important;
          max-width: 60px !important;
        }
      }

      .select-all-container {
        padding: 0 !important;

        button {
          &.dd-trigger {
            margin: 0 !important;
            padding: 0 !important;
            margin-left: -20px !important;
          }
        }
      }
    }

    .table-data {
      .submission {
        justify-content: stretch !important;

        .table-cell {
          width: 100px !important;
          min-width: 100px !important;
          max-width: 100px !important;
          padding: 0 !important;

          &.checkbox-cell {
            width: 60px !important;
            min-width: 60px !important;
            max-width: 60px !important;
          }

          &.id-cell {
            width: 60px !important;
            min-width: 60px !important;
            max-width: 60px !important;
          }
        }
      }
    }
  }

  .submission-table-container {
    &.collapsed {
      .submission-header {
        height: 0 !important;
        padding: 0 !important;
        opacity: 0 !important;
        pointer-events: none !important;
      }
    }
  }

  .submission-header {
    transition: all 0.3s ease !important;
  }

  .funnel-container {
    .submissions-list {
      .submissions-container {
        .submissions-content {
          padding: 0 !important;
        }
      }
    }
  }

  #single-submission {
    .scoring-pane-container {
      width: auto !important;
      max-width: unset !important;
      flex: 0.5 !important;

      .scoring-pane {
        .scoring-tab {
          padding: 16px !important;
        }

        div {
          &#SubmissionActivities {
            height: calc(100vh - 323px) !important;
            padding: 32px !important;
          }
        }
      }
    }

    .submission-info {
      max-width: unset !important;
      min-width: unset !important;
      flex: 0.5 !important;
    }
  }
}

// Tablet overrides
@media only screen and (max-width: 930px) {
  .submissions-container {
    .submission-table-container {
      &.collapsed {
        width: 0% !important;
        min-width: 0% !important;
      }

      &.collapsed + .single-submission-container {
        width: 100% !important;
      }
    }
  }
}
