@import "../../../components/assets/stylesheets/global";
// Sass team discourages the continued use of the @import rule.
// Replace @import with @use by 2021.
// For more info visit: https://sass-lang.com/documentation/at-rules/import

#MembersManagementContainer {
  .groups {
    overflow: unset;

    .collapsible-group {
      padding: 24px 24px 0;

      .boxy {
        padding: 0;
        transition: all 0.3s ease;
      }

      .group-header {
        display: flex;
        padding: 16px 16px 0;
        cursor: pointer;
        align-items: center;

        &.padding-bottom-16 {
          padding-bottom: 16px;
        }

        > div {
          height: 16px;
          line-height: 16px;
        }

        .group-collapse {
          @include directional-margin(8px, 0);
        }

        .group-name {
          font-weight: 500;
        }

        .dd-trigger {
          @include directional-margin(initial, auto);
        }
      }

      .group-members {
        padding: 16px;
        position: relative;

        .divider {
          @include directional-position(auto, 0);
          position: absolute;
        }

        .member {
          display: flex;
          align-items: center;

          .member-role {
            @include directional-margin(initial, auto);
            width: 150px;

            .Select {
              box-shadow: none;
              border: 0;

              .Select-control {
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }
          }
        }
      }
    }

    .remove-member {
      background: $fail-bg;
      color: $fail;

      &::before {
        content: "\F1C0";
        display: inline-block;
        font-family: "Material Design Icons";
      }
    }
  }
}

#edit-group-container {
  padding: 24px 24px 0;

  .boxy {
    padding: 0;
  }

  .create-new-group {
    padding: 16px;

    .icon-button {
      display: flex;
      align-items: center;
      color: $blue;
      height: 16px;
      margin: 0;

      .icon-button-text {
        font-weight: bold;
        padding-left: 8px;
        text-transform: uppercase;
        line-height: 16px;
      }
    }
  }

  .create-group-form {
    .form-inputs {
      height: 186px;
      padding: 24px 24px 32px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .Select-control {
        display: flex;
        align-items: center;
      }
    }

    .footer-actions {
      padding: 0;
      height: 48px;
    }
  }
}

.modal {
  &.is-active {
    overflow: scroll;
    background-color: transparentize($black, 0.14);

    .modal-background {
      background-color: transparent;
    }
  }
}

.program-form {
  &.modal {
    &.is-active {
      .modal-background {
        background-color: $white;
      }
    }
  }
}

.modal-content,
.modal-card {
  margin: 0 auto;
  max-height: 100%;
  width: 640px;
}
